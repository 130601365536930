import { Dispatch } from "react";
import { AppAction } from "../../Models/Action";
import { IServices } from "../Store";
import {
  GET_PRISSPOTTERS_COMPLETE,
  EDIT_PRISSPOTTERS_COMPLETE,
  REMOVE_PRISSPOTTERS_COMPLETE,
  REMOVE_PRISSPOTTER_PERMISSIONS_COMPLETE,
} from "./actions";
import { SHOW_SPINNER } from "../system/actions";
import ApiResponse from "../../Models/ApiResponse";
import { Prisspotter } from "../../Models/Prisspotter";
import GetPrisspottersResponse from "../../Models/DTO/GetPrisspottersResponse";
import { StationPermission } from "../../Models/StationPermission";
import { dispatchError, handleError } from "../actionCreatorsHelper";

export const getPrisspotters = () => async (
  dispatch: Dispatch<AppAction>,
  getState: any,
  services: IServices
) => {
  try {
    dispatch({
      type: SHOW_SPINNER,
    });
    const response = await services.subordinateUsersApiService.getPrisspotters();
    const onSuccess = (data: GetPrisspottersResponse) => {
      dispatch({
        type: GET_PRISSPOTTERS_COMPLETE,
        payload: data,
      });
    };
    processResponse(response, services, dispatch, onSuccess);
  } catch (error) {
    dispatchError(error, dispatch);
  }
};

export const createPrisspotter = (
  actor: Prisspotter,
  password: string
) => async (
  dispatch: Dispatch<AppAction>,
  getState: any,
  services: IServices
) => {
  try {
    dispatch({
      type: SHOW_SPINNER,
    });
    const response = await services.subordinateUsersApiService.createPrisspotter(
      actor,
      password
    );
    const onSuccess = () => {
      getPrisspotters()(dispatch, getState, services);
    };
    processResponse(response, services, dispatch, onSuccess);
  } catch (error) {
    dispatchError(error, dispatch);
  }
};

export const editPrisspotter = (
  actorNumber: number,
  password: string
) => async (
  dispatch: Dispatch<AppAction>,
  getState: any,
  services: IServices
) => {
  try {
    dispatch({
      type: SHOW_SPINNER,
    });
    const response = await services.subordinateUsersApiService.editPrisspotter(
      actorNumber,
      password
    );
    const onSuccess = () => {
      dispatch({
        type: EDIT_PRISSPOTTERS_COMPLETE,
        payload: { actorNumber },
      });
    };
    processResponse(response, services, dispatch, onSuccess);
  } catch (error) {
    dispatchError(error, dispatch);
  }
};

export const removePrisspotter = (actorNumber: number) => async (
  dispatch: Dispatch<AppAction>,
  getState: any,
  services: IServices
) => {
  try {
    dispatch({
      type: SHOW_SPINNER,
    });
    const response = await services.subordinateUsersApiService.removeSubordinateUser(
      actorNumber
    );
    const onSuccess = () => {
      dispatch({
        type: REMOVE_PRISSPOTTERS_COMPLETE,
        payload: actorNumber,
      });
    };
    processResponse(response, services, dispatch, onSuccess);
  } catch (error) {
    dispatchError(error, dispatch);
  }
};

export const addPermissions = (
  actorNumber: number,
  permissions: StationPermission[]
) => async (
  dispatch: Dispatch<AppAction>,
  getState: any,
  services: IServices
) => {
  try {
    dispatch({
      type: SHOW_SPINNER,
    });
    const stationNumbers = permissions.map((st) => st.stationNumber);

    const response = await services.subordinateUsersApiService.addPermissions(
      actorNumber,
      new Date(),
      new Date("2070-10-10"), //some date in a distant future
      stationNumbers
    );
    const onSuccess = (data: any) => {
      getPrisspotters()(dispatch, getState, services);
    };
    processResponse(response, services, dispatch, onSuccess);
  } catch (error) {
    dispatchError(error, dispatch);
  }
};

export const removePermissions = (
  actorNumber: number,
  permissions: StationPermission[]
) => async (
  dispatch: Dispatch<AppAction>,
  getState: any,
  services: IServices
) => {
  try {
    dispatch({
      type: SHOW_SPINNER,
    });

    const agreementNumbers = permissions.map((st) => st.agreementId);
    const response = await services.subordinateUsersApiService.removePermissions(
      actorNumber,
      agreementNumbers
    );
    const onSuccess = () => {
      dispatch({
        type: REMOVE_PRISSPOTTER_PERMISSIONS_COMPLETE,
        payload: actorNumber,
      });
    };
    processResponse(response, services, dispatch, onSuccess);
  } catch (error) {
    dispatchError(error, dispatch);
  }
};

function processResponse<T>(
  response: ApiResponse<T>,
  services: IServices,
  dispatch: Dispatch<AppAction>,
  onsuccess: (data: T) => void
) {
  if (response.isError) {
    handleError(response, services, dispatch);
  } else {
    onsuccess(response.data!);
  }
}

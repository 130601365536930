import { CompetitorLists } from "../../Models/CompetitorLists";
import { RegisterPriceRequest } from "../../Models/DTO/RegisterPrice";
import { UnoxStation } from "../../Models/Station";
import ListPricesResponse from "../../Models/DTO/ListPricesResponse";

export const GET_MY_STATIONS = "GET_MY_STATIONS";
export const GET_MY_STATIONS_COMPLETE = "GET_MY_STATIONS_COMPLETE";
export const GET_LIST_PRICES_COMPLETE = "GET_LIST_PRICES_COMPLETE";
export const GET_COMPETITOR_STATIONS = "GET_COMPETITOR_STATIONS";
export const GET_COMPETITOR_STATIONS_COMPLETE =
  "GET_COMPETITOR_STATIONS_COMPLETE";
export const REGISTER_PRICE = "REGISTER_PRICE";
export const REGISTER_PRICE_COMPLETE = "REGISTER_PRICE_COMPLETE";
export const EXPAND_COMPETITOR_STATION = "EXPAND_COMPETITOR_STATION";
export const EXPAND_MY_STATION = "EXPAND_MY_STATION";
export const CLEAR_PRICE_REGISTRATION_STATION = "CLEAR_PRICE_REGISTRATION_STATION";

interface GetMyStationsAction {
  type: typeof GET_MY_STATIONS;
}

interface GetMyStationsCompleteAction {
  type: typeof GET_MY_STATIONS_COMPLETE;
  payload: UnoxStation[];
}

interface GetListPricesCompleteAction {
  type: typeof GET_LIST_PRICES_COMPLETE;
  payload: ListPricesResponse;
}

interface GetCompetitorStationsAction {
  type: typeof GET_COMPETITOR_STATIONS;
}

interface GetCompetitorStationsCompleteAction {
  type: typeof GET_COMPETITOR_STATIONS_COMPLETE;
  payload: CompetitorLists;
}

interface RegisterPriceAction {
  type: typeof REGISTER_PRICE;
  payload: RegisterPriceRequest;
}

interface RegisterPriceCompleteAction {
  type: typeof REGISTER_PRICE_COMPLETE;
  payload: { stationId: string; observationTime: Date; actorId: string, dieselPrice: number, unleadedPrice: number };
}

interface ExpandCompetitorStation {
  type: typeof EXPAND_COMPETITOR_STATION;
  payload: string | null;
}

interface ExpandMyStation {
  type: typeof EXPAND_MY_STATION;
  payload: string | null;
}

interface ClearPriceRegistationStation {
  type: typeof CLEAR_PRICE_REGISTRATION_STATION;
}

export type StationActionTypes =
  | GetMyStationsAction
  | GetMyStationsCompleteAction
  | GetListPricesCompleteAction
  | GetCompetitorStationsAction
  | GetCompetitorStationsCompleteAction
  | RegisterPriceAction
  | RegisterPriceCompleteAction
  | ExpandCompetitorStation
  | ExpandMyStation
  | ClearPriceRegistationStation;

import { Dispatch } from "react";

import { AppAction } from "../../Models/Action";
import { CLEAR_ALERT, SET_LOCAL_SETTINGS } from "./actions";
import { LocalSettings } from "../../Models/LocalSettings";
import { IServices } from "../Store";

export const clearAlert = () => (dispatch: Dispatch<AppAction>) => {
  dispatch({
    type: CLEAR_ALERT,
  });
};

export const restoreLocalSettings =
  () => (dispatch: Dispatch<AppAction>, getState: any, services: IServices) => {
    try {
      const settings = services.localSettingsService.restoreLocalSettings();
      if (settings) {
        dispatch({
          type: SET_LOCAL_SETTINGS,
          payload: settings,
        });
        return true;
      } else {
        services.authService.logOff();
        return false;
      }
    } catch (error) {
      return false;
    }
  };

export const setLocalSettings =
  (settings: LocalSettings) =>
  (dispatch: Dispatch<AppAction>, getState: any, services: IServices) => {
    dispatch({
      type: SET_LOCAL_SETTINGS,
      payload: settings,
    });
    services.localSettingsService.storeLocalSettings(settings);
  };

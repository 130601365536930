import React, { useEffect, useRef, useState } from "react";
import { Dispatch, AnyAction, bindActionCreators } from "redux";
import { connect, ConnectedProps } from "react-redux";
import {
  Button,
  Collapse,
  Grid,
  Snackbar,
  Typography,
} from "@material-ui/core";

import { ApplicationState } from "../../store/Store";
import { clearAlert } from "../../store/system/actionCreators";
import {
  getPrisspotters,
  editPrisspotter,
  removePrisspotter,
  addPermissions,
  removePermissions,
  createPrisspotter,
} from "../../store/prisspotter/actionCreators";
import { UnoxColors } from "../../constants/theme";
import TopBar from "../../components/TopBar";
import { PageType } from "../../Models/PageType";
import { Alert } from "@material-ui/lab";
import LoadingOverlay from "../../components/LoadingOverlay";
import CreatePrisspotter from "./components/CreatePrisspotter";
import PrisspotterList from "./components/PrisspotterList";
import PrisspotterPermissionList from "./components/PrisspotterPermissionList";

const PrisspotterPage: React.FC<PrisspotterConnectedProps> = (props) => {
  const getPrisspotters = props.getPrisspotters;
  useEffect(() => {
    getPrisspotters();
  }, [getPrisspotters]);

  const [expandedAccordionKey, setExpandedAccordionKey] = useState<
    number | null
  >(null);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const handlePrisspotterCreate = (
    name: string,
    userName: string,
    password: string
  ) => {
    setShowCreateForm(false);
    props.createPrisspotter(
      { name, userName, permissions: [], actorNumber: -1 },
      password
    );
  };

  const createFormRef = useRef<HTMLDivElement>(null);

  return (
    <>
      <Grid container direction="column">
        <TopBar currentPage={PageType.Prisspottere}></TopBar>
        <Typography variant="h5">Prisspottere</Typography>
        <Button
          variant="outlined"
          style={{
            backgroundColor: UnoxColors.Orange,
            color: UnoxColors.Black,
            marginTop: 10,
          }}
          onClick={() => setShowCreateForm(true)}
        >
          Opret ny prisspotter
        </Button>
        <Collapse
          in={showCreateForm}
          onEntered={() => {
            if (createFormRef && createFormRef.current) {
              createFormRef.current.scrollIntoView({
                behavior: "smooth",
                block: "start",
              });
            }
          }}
        >
          <Grid ref={createFormRef}>
            <CreatePrisspotter
              cancel={() => setShowCreateForm(false)}
              createPrisspotter={handlePrisspotterCreate}
            ></CreatePrisspotter>
          </Grid>
        </Collapse>
        <Typography
          variant="body2"
          style={{ marginTop: 10, marginBottom: 10, fontWeight: "bold" }}
        >
          Aftaler
        </Typography>
        <PrisspotterPermissionList
          prisspotters={props.prisspotters.filter(
            (actor) => actor.permissions && actor.permissions.length > 0
          )}
          removePermissions={props.removePermissions}
          editPrisspotter={props.editPrisspotter}
          expandedActor={expandedAccordionKey}
          setExpandedActor={(actor) => setExpandedAccordionKey(actor)}
        ></PrisspotterPermissionList>

        <Typography
          variant="body2"
          style={{ marginTop: 10, marginBottom: 10, fontWeight: "bold" }}
        >
          Mine prisspottere
        </Typography>
        <PrisspotterList
          prisspotters={props.prisspotters}
          permissionStations={props.permissionStations}
          addPermissions={props.addPermissions}
          editPrisspotter={props.editPrisspotter}
          removePrisspotter={props.removePrisspotter}
          expandedActor={expandedAccordionKey}
          setExpandedActor={(actor) => setExpandedAccordionKey(actor)}
        ></PrisspotterList>
        {/* remain some space in the bottom to allow scrolling content to the top */}
        <Grid style={{ height: 200, width: 1 }}></Grid>
      </Grid>
      <Snackbar
        autoHideDuration={parseInt(process.env.REACT_APP_ALERT_TIMEOUT || "0")}
        open={!!props.alert}
        onClose={() => props.clearAlert()}
      >
        <Alert severity={props.alert?.type}>{props.alert?.text}</Alert>
      </Snackbar>
      <LoadingOverlay loading={props.loading} />
    </>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  alert: state.system.alert,
  loading: state.system.loading,
  prisspotters: state.prisspotter.Prisspotters,
  permissionStations: state.prisspotter.permissionStations,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      getPrisspotters,
      createPrisspotter,
      editPrisspotter,
      removePrisspotter,
      addPermissions,
      removePermissions,
      clearAlert,
    },
    dispatch
  );

const connector = connect(mapStateToProps, mapDispatchToProps);

type PrisspotterConnectedProps = ConnectedProps<typeof connector>;

export default connector(PrisspotterPage);

import React from "react";
import { UnoxStation } from "../../../Models/Station";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { UnoxColors } from "../../../constants/theme";
import { getLongDateString } from "../../../services/DateFormatter";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    accordionHeader: {
      "&.Mui-expanded": {
        backgroundColor: UnoxColors.Orange,
      },
    },
  })
);

type MyStationProps = {
  station: UnoxStation;
  listPriceDiesel: number | null;
  listPriceUnleaded: number | null;
  expandedStationId: string | null;
  expandStation: (stationId: string | null) => void;
};

const MyStations: React.FC<MyStationProps> = ({
  station,
  listPriceDiesel,
  listPriceUnleaded,
  expandedStationId,
  expandStation,
}) => {
  const classes = useStyles();
  const isSmallScreen = useMediaQuery("(max-width:355px)");
  return (
    <Accordion
      style={{ width: "100%" }}
      expanded={expandedStationId === station.id}
      onChange={(_: object, expanded: boolean) =>
        expandStation(expanded ? station.id : null)
      }
    >
      <AccordionSummary
        aria-controls="panel1a-content"
        className={classes.accordionHeader}
      >
        <Grid container direction="column">
          <Grid container direction="row">
            <Grid container direction="column" style={{ flex: 1.3 }}>
              <Grid item>
                <Typography variant="body2">{station.stationNumber}</Typography>
              </Grid>
              <Grid item>
                <Typography variant="subtitle2" style={{ fontWeight: "bold" }}>
                  {station.name}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2">{station.address}</Typography>
              </Grid>
            </Grid>
            <Grid
              container
              direction="column"
              style={{ flex: 1 }}
              alignItems="flex-end"
            >
              <Grid item>
                <Typography
                  variant="body2"
                  style={{
                    fontWeight: "bold",
                    letterSpacing: isSmallScreen ? -1.1 : 0,
                  }}
                >
                  {getLongDateString(station.updated)}
                </Typography>
              </Grid>
              <Grid item container direction="row" justify="flex-end">
                <Typography variant="body2" style={{ fontWeight: "bold" }}>
                  B:{" "}
                  <span
                    style={
                      station.lastUnleaded < 10 ? { marginLeft: "1ch" } : {}
                    }
                  >
                    {" "}
                    {station.lastUnleaded?.toFixed(2)}
                  </span>
                </Typography>
                <Typography variant="body2">
                  (
                  {(listPriceUnleaded && station.lastUnleaded
                    ? listPriceUnleaded - station.lastUnleaded
                    : 0
                  ).toFixed(2)}
                  )
                </Typography>
              </Grid>
              <Grid item container direction="row" justify="flex-end">
                <Typography variant="body2" style={{ fontWeight: "bold" }}>
                  D:{" "}
                  <span
                    style={station.lastDiesel < 10 ? { marginLeft: "1ch" } : {}}
                  >
                    {station.lastDiesel?.toFixed(2)}
                  </span>
                </Typography>
                <Typography variant="body2">
                  (
                  {(listPriceDiesel && station.lastDiesel
                    ? listPriceDiesel - station.lastDiesel
                    : 0
                  ).toFixed(2)}
                  )
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item style={{ textAlign: "right" }}>
            {expandedStationId !== station.id && (
              <ExpandMore fontSize="small" style={{ fontSize: 16 }} />
            )}
            {expandedStationId === station.id && (
              <ExpandLess fontSize="small" style={{ fontSize: 16 }} />
            )}
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails style={{ marginLeft: 20, marginRight: 20 }}>
        {station.observation && (
          <Grid container direction="column">
            <Typography variant="body2">B:</Typography>
            <Typography variant="body2">
              {station.observation.ulObservation_price?.toFixed(2)}{" "}
              {station.observation.ulObservation_stationName}
            </Typography>
            {station.observation.ulObservation_dateTime && (
              <Typography variant="body2">
                {getLongDateString(station.observation.ulObservation_dateTime)}
              </Typography>
            )}
            <Typography variant="body2" style={{ marginTop: 10 }}>
              D:
            </Typography>
            <Typography variant="body2">
              {station.observation.dieselObservation_price?.toFixed(2)}{" "}
              {station.observation.dieselObservation_stationName}
            </Typography>
            {station.observation.dieselObservation_dateTime && (
              <Typography variant="body2">
                {getLongDateString(
                  station.observation.dieselObservation_dateTime
                )}
              </Typography>
            )}
          </Grid>
        )}
        {!station.observation && (
          <Typography variant="body2">
            {getLongDateString(station.updated)}
          </Typography>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

type MyStationsListProps = {
  myStations: UnoxStation[];
  listPriceDiesel: number | null;
  listPriceUnleaded: number | null;
  expandedStationId: string | null;
  expandStation: (stationId: string | null) => void;
};

const MyStationsList: React.FC<MyStationsListProps> = (
  props: MyStationsListProps
) => {
  return (
    <>
      <Grid container justify="center">
        {props.myStations.map((station: UnoxStation) => (
          <MyStations
            station={station}
            listPriceDiesel={props.listPriceDiesel}
            listPriceUnleaded={props.listPriceUnleaded}
            key={station.stationNumber}
            expandedStationId={props.expandedStationId}
            expandStation={props.expandStation}
          />
        ))}
      </Grid>
    </>
  );
};

export default MyStationsList;

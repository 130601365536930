import React, { useState } from "react";
import {
  Drawer,
  Grid,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Menu,
  ListItemIcon,
  Typography,
} from "@material-ui/core";
import {
  ExpandMore,
  Home,
  Menu as MenuIcon,
  NoEncryption,
  ArrowBack,
} from "@material-ui/icons";

import { Dispatch, AnyAction, bindActionCreators } from "redux";
import { connect, ConnectedProps } from "react-redux";
import { useHistory } from "react-router-dom";

import { PageType, PagePath } from "../Models/PageType";
import { logoffUser } from "../store/user/actionCreators";
import { ApplicationState } from "../store/Store";
import StationIcon from "../assets/icons/Station.png";
import PriceReportingIcon from "../assets/icons/PriceReporting.png";
import UserIcon from "../assets/icons/User.png";
import FaceIcon from "../assets/icons/Face.png";
import LocationIcon from "../assets/icons/Location.png";
import QuestionMarkIcon from "../assets/icons/QuestionMark.png";
import { UserRole } from "../Models/UserRole";

const TopBar: React.FC<TopBarConnectedProps> = ({
  user,
  logoffUser,
  currentPage,
  profilePicture,
}) => {
  function ListItemLink(props: any) {
    const { targetPage, href, ...nestedProps } = props;
    return (
      <ListItem
        onClick={() => history.push(href)}
        selected={targetPage === currentPage}
        {...nestedProps}
      />
    );
  }

  const [menuOpened, setMenuOpened] = useState(false);
  const [
    userMenuAnchorRef,
    setUserMenuAnchorRef,
  ] = React.useState<null | HTMLElement>(null);

  const history = useHistory();

  const handleUserMenuClick = (event: React.MouseEvent<any>) => {
    setUserMenuAnchorRef(event.currentTarget);
  };

  const hangleLogOff = async () => {
    await logoffUser();
    history.push(PagePath.Login);
  };

  return (
    <>
      <Grid
        item
        container
        justify="space-between"
        alignItems="center"
        direction="row"
        style={{ marginBottom: 20, marginTop: 10 }}
      >
        <Grid item container style={{ width: "Auto" }} alignItems="center">
          <Home onClick={() => history.push(PagePath.Home)} />
          <MenuIcon onClick={() => setMenuOpened(true)} />
          {currentPage !== PageType.Home && history.length > 2 && (
            // back button
            <Grid
              item
              container
              direction="row"
              style={{ marginLeft: 10, width: "Auto", cursor: "pointer" }}
              onClick={()=>history.goBack()}
            >
              <ArrowBack fontSize="small" />
              <Typography variant="body2" style={{userSelect: "none"}}>Tilbage</Typography>
            </Grid>
          )}
        </Grid>
        <Grid
          item
          container
          direction="row"
          alignItems="center"
          style={{ display: "flex", width: "auto" }}
          onClick={handleUserMenuClick}
        >
          {profilePicture && (
            <img
              src={profilePicture}
              alt="profilbillede"
              style={{
                width: 35,
                height: 35,
                borderRadius: 17,
                objectFit: "fill",
              }}
            />
          )}
          <ExpandMore />
        </Grid>
      </Grid>

      <Menu
        anchorEl={userMenuAnchorRef}
        keepMounted
        open={!!userMenuAnchorRef}
        onClose={() => setUserMenuAnchorRef(null)}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <MenuItem onClick={() => history.push(PagePath.UserProfile)}>
          <ListItemIcon>
            <img src={UserIcon} height={25} width={25} alt="Min profil" />
          </ListItemIcon>
          <ListItemText primary="Min profil" />
        </MenuItem>
        <MenuItem onClick={() => history.push(PagePath.ChangeProfilePicture)}>
          <ListItemIcon>
            <img
              src={FaceIcon}
              height={25}
              width={25}
              alt="Skift profilbillede"
            />
          </ListItemIcon>
          <ListItemText primary="Skift profilbillede" />
        </MenuItem>
        <MenuItem onClick={() => hangleLogOff()}>
          <ListItemIcon>
            <NoEncryption fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Log af" />
        </MenuItem>
      </Menu>

      <React.Fragment>
        <Drawer
          anchor="left"
          open={menuOpened}
          onClose={() => setMenuOpened(false)}
        >
          <List component="nav">
            <ListItemLink
              href={PagePath.PriceReporting}
              targetPage={PageType.PriceReporting}
            >
              <ListItemIcon>
                <img
                  src={PriceReportingIcon}
                  height={30}
                  width={30}
                  alt="Prisindrapportering"
                />
              </ListItemIcon>
              <ListItemText primary="Prisindrapportering" />
            </ListItemLink>

            <ListItemLink
              href={PagePath.MyStations}
              targetPage={PageType.MyStations}
            >
              <ListItemIcon>
                <img
                  src={StationIcon}
                  height={30}
                  width={30}
                  alt="Mine stationer"
                />
              </ListItemIcon>
              <ListItemText primary="Mine stationer" />
            </ListItemLink>

            {user?.role === UserRole.DistrictPartner && (
              <>
                <ListItemLink
                  href={PagePath.Ferieafloesere}
                  targetPage={PageType.Ferieafloesere}
                >
                  <ListItemIcon>
                    <img
                      src={FaceIcon}
                      height={30}
                      width={30}
                      alt="Ferieafløsere"
                    />
                  </ListItemIcon>
                  <ListItemText primary="Ferieafløsere" />
                </ListItemLink>

                <ListItemLink
                  href={PagePath.Prisspottere}
                  targetPage={PageType.Prisspottere}
                >
                  <ListItemIcon>
                    <img
                      src={LocationIcon}
                      height={30}
                      width={30}
                      alt="Prisspottere"
                    />
                  </ListItemIcon>
                  <ListItemText primary="Prisspottere" />
                </ListItemLink>
                <ListItemLink
                  href={PagePath.Contacts}
                  targetPage={PageType.Contacts}
                >
                  <ListItemIcon>
                    <img
                      src={QuestionMarkIcon}
                      height={30}
                      width={30}
                      alt="Ring rigtigt"
                    />
                  </ListItemIcon>
                  <ListItemText primary="Ring rigtigt" />
                </ListItemLink>
              </>
            )}
          </List>
        </Drawer>
      </React.Fragment>
    </>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  user: state.user.currentUser,
  profilePicture: state.user.profilePictureUrl,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      logoffUser,
    },
    dispatch
  );

const connector = connect(mapStateToProps, mapDispatchToProps);

type TopBarConnectedProps = ConnectedProps<typeof connector> & {
  currentPage: PageType;
};

export default connector(TopBar);

import { CompetitorLists } from "../../Models/CompetitorLists";
import { UnoxStation } from "../../Models/Station";
import { LOGOFF_COMPLETE, UserActionTypes } from "../user/actions";
import {
  GET_MY_STATIONS_COMPLETE,
  StationActionTypes,
  GET_COMPETITOR_STATIONS_COMPLETE,
  GET_LIST_PRICES_COMPLETE,
  REGISTER_PRICE_COMPLETE,
  EXPAND_COMPETITOR_STATION,
  EXPAND_MY_STATION,
  CLEAR_PRICE_REGISTRATION_STATION,
} from "./actions";

export interface StationState {
  myStations: UnoxStation[];
  competitorStations: CompetitorLists;
  listPriceDiesel: number | null;
  listPriceUnleaded: number | null;
  expandedCompetitorStationId: string | null;
  expandedMyStationId: string | null;
  successfullyReportedCompetitorStationId: string | null;
}

const stationInitialState: StationState = {
  myStations: [],
  competitorStations: {
    MustList: [],
    MayList: [],
    CanList: [],
  },
  listPriceDiesel: null,
  listPriceUnleaded: null,
  expandedCompetitorStationId: null,
  expandedMyStationId: null,
  successfullyReportedCompetitorStationId: null,
};

const stationReducer = (
  state = stationInitialState,
  action: StationActionTypes | UserActionTypes
): StationState => {
  switch (action.type) {
    case GET_MY_STATIONS_COMPLETE: {
      return {
        ...state,
        myStations: action.payload,
      };
    }
    case GET_COMPETITOR_STATIONS_COMPLETE: {
      return {
        ...state,
        competitorStations: action.payload,
        expandedCompetitorStationId: null,
      };
    }
    case GET_LIST_PRICES_COMPLETE: {
      return {
        ...state,
        listPriceUnleaded: action.payload.listPriceUnleaded,
        listPriceDiesel: action.payload.listPriceDiesel,
      };
    }
    case REGISTER_PRICE_COMPLETE: {
      return {
        ...state,
        competitorStations: {
          MustList: state.competitorStations.MustList.map((st) =>
            st.id === action.payload.stationId
              ? {
                  ...st,
                  updated: action.payload.observationTime,
                  lastPriceReporterId: action.payload.actorId,
                  lastDiesel: action.payload.dieselPrice,
                  lastUnleaded: action.payload.unleadedPrice,
                }
              : st
          ),
          MayList: state.competitorStations.MayList.map((st) =>
            st.id === action.payload.stationId
              ? {
                  ...st,
                  updated: action.payload.observationTime,
                  lastPriceReporterId: action.payload.actorId,
                  lastDiesel: action.payload.dieselPrice,
                  lastUnleaded: action.payload.unleadedPrice,
                }
              : st
          ),
          CanList: state.competitorStations.CanList.map((st) =>
            st.id === action.payload.stationId
              ? {
                  ...st,
                  updated: action.payload.observationTime,
                  lastPriceReporterId: action.payload.actorId,
                  lastDiesel: action.payload.dieselPrice,
                  lastUnleaded: action.payload.unleadedPrice,
                }
              : st
          ),
        },
        successfullyReportedCompetitorStationId: action.payload.stationId
      };
    }
    case EXPAND_COMPETITOR_STATION: {
      return {
        ...state,
        expandedCompetitorStationId: action.payload,
      };
    }
    case EXPAND_MY_STATION: {
      return {
        ...state,
        expandedMyStationId: action.payload,
      };
    }
    case CLEAR_PRICE_REGISTRATION_STATION: {
      return {
        ...state,
        successfullyReportedCompetitorStationId: null,
      }
    }
    case LOGOFF_COMPLETE: {
      return stationInitialState;
    }
    default:
      return state;
  }
};

export default stationReducer;

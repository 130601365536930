import { applyMiddleware, combineReducers, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";

import { actionLogger } from "./middlewares/ActionLogger";
import stationReducer from "./station/reducer";
import { StationState } from "./station/reducer";
import thunk from "redux-thunk";
import { IStationApiService } from "../services/API/IStationApiService";
import { StationApiService } from "../services/API/StationApiService";
import { IGpsService } from "../services/IGpsService";
import { GpsService } from "../services/GpsService";
import { UserState } from "./user/reducer";
import userReducer from "./user/reducer";
import IAuthService from "../services/IAuthService";
import { AuthAPIService } from "../services/API/AuthApiService";
import IUserApiService from "../services/API/IUserApiService";
import { UserApiService } from "../services/API/UserApiService";
import { AuthService } from "../services/AuthService";
import { HttpService } from "../services/API/HttpService";
import systemReducer from "./system/reducer";
import ferieafloeserReducer from "./ferieafloeser/reducer";
import { SystemState } from "./system/reducer";
import ISubordinateUserApiService from "../services/API/ISubordinateUserApiService";
import { SubordinateUserApiService } from "../services/API/SubordinateUserApiService";
import { FerieafloeserState } from './ferieafloeser/reducer';
import { PrisspotterState } from './prisspotter/reducer';
import PrisspotterReducer from './prisspotter/reducer';
import IContactApiService from '../services/API/IContactApiService';
import { ContactApiService } from '../services/API/ContactApiService';
import { ContactState } from './contacts/reducer';
import contactReducer from './contacts/reducer';
import ILocalSettingsService from '../services/ILocalSettingsService';
import { LocalSettingsService } from '../services/LocalSettingsService';

export interface ApplicationState {
  station: StationState;
  user: UserState;
  system: SystemState;
  ferieafloeser: FerieafloeserState;
  prisspotter: PrisspotterState;
  contact: ContactState;
}

export interface IServices {
  stationApiService: IStationApiService;
  gpsService: IGpsService;
  authService: IAuthService;
  userApiService: IUserApiService;
  subordinateUsersApiService: ISubordinateUserApiService;
  contactApiService: IContactApiService;
  localSettingsService: ILocalSettingsService;
}

const authApi = new AuthAPIService();
const auth = new AuthService(authApi);
const httpService = new HttpService(auth);

const injectedServices: IServices = {
  stationApiService: new StationApiService(httpService),
  authService: auth,
  gpsService: new GpsService(),
  userApiService: new UserApiService(httpService),
  subordinateUsersApiService: new SubordinateUserApiService(httpService),
  contactApiService: new ContactApiService(httpService),
  localSettingsService: new LocalSettingsService(),
};

const middlewares = [thunk.withExtraArgument(injectedServices)];

//adding logger for the dev build
if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
  middlewares.push(actionLogger);
}

const reducer = combineReducers({
  station: stationReducer,
  user: userReducer,
  system: systemReducer,
  ferieafloeser: ferieafloeserReducer,
  prisspotter: PrisspotterReducer,
  contact: contactReducer,
});
/*const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middlewares))
);*/
//const store = createStore(reducer);
//const store = createStore(reducer, compose(applyMiddleware(...middlewares)));
const store = createStore(
  reducer,
  composeWithDevTools(applyMiddleware(...middlewares))
);

export default store;

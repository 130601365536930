import { CompetitorLists } from "../../Models/CompetitorLists";
import { RegisterPriceRequest } from "../../Models/DTO/RegisterPrice";
import {
  CompetitorStation,
  ReportingType,
  Station,
  UnoxStation,
} from "../../Models/Station";
import { IStationApiService } from "./IStationApiService";
import { StationDTO } from "../../Models/DTO/CompetitorStationsResponse";
import ApiResponse from "../../Models/ApiResponse";
import IHttpService from "./IHttpService";
import FuelServerResponse from "../../Models/DTO/FuelServerResponse";
import ListPricesResponse from "../../Models/DTO/ListPricesResponse";

export class StationApiService implements IStationApiService {
  constructor(private httpService: IHttpService) {}

  async getMyStations(
    coords: Coordinates | null
  ): Promise<ApiResponse<UnoxStation[]>> {
    let url = `${process.env.REACT_APP_SERVICE}api/report/GetUnoxStations`;
    if(coords){
      url += `?latitude=${coords.latitude}&longitude=${coords.longitude}`;
    }
    const response = await this.httpService.get<
      FuelServerResponse<StationDTO[]>
    >(url);

    if (response.isError || !response.data) {
      return { isError: true, IsNotAuthorized: response.IsNotAuthorized };
    }

    const data = response.data;

    if (data.isOk) {
      const mappedData = data.result.map((dto) =>
        this.mapStationDtoToUnoxStation(dto)
      );
      return { isError: false, data: mappedData };
    } else {
      return {
        isError: true,
        error: "Kan ikke indlæse stationer. " + (data.errorDescription ?? ""),
      };
    }
  }

  async getCompetitorStations(
    coords: Coordinates | null
  ): Promise<ApiResponse<CompetitorLists>> {
    let url = `${process.env.REACT_APP_SERVICE}api/report/GetCompetitorsStations`;
    if(coords){
      url += `?latitude=${coords.latitude}&longitude=${coords.longitude}`;
    }
    const response = await this.httpService.get<
      FuelServerResponse<StationDTO[]>
    >(url);

    if (response.isError || !response.data) {
      return { isError: true, IsNotAuthorized: response.IsNotAuthorized };
    }

    const data = response.data;

    if (data.isOk) {
      const mappedData = data.result.map((dto) =>
        this.mapStationDtoToStation(dto)
      );
      const result = {
        MustList: mappedData.filter(
          (st) => st.reportingType === ReportingType.Must
        ),
        MayList: mappedData.filter(
          (st) => st.reportingType === ReportingType.May
        ),
        CanList: mappedData.filter(
          (st) => st.reportingType === ReportingType.Can
        ),
      };

      return { isError: false, data: result };
    } else {
      return {
        isError: true,
        error: "Kan ikke indlæse stationer. " + (data.errorDescription ?? ""),
      };
    }
  }

  async getListPrices(): Promise<ApiResponse<ListPricesResponse>> {
    const url = `${process.env.REACT_APP_SERVICE}api/report/GetListPrices`;
    const response = await this.httpService.get<
      FuelServerResponse<ListPricesResponse>
    >(url);

    if (response.isError || !response.data) {
      return { isError: true, IsNotAuthorized: response.IsNotAuthorized };
    }

    const data = response.data;

    if (data.isOk) {
      return { isError: false, data: data.result };
    } else {
      return {
        isError: true,
        error: "Kan ikke indlæse list priser. " + (data.errorDescription ?? ""),
      };
    }
  }

  async registerPrice(price: RegisterPriceRequest): Promise<ApiResponse<Date>> {
    const url = `${process.env.REACT_APP_SERVICE}api/report/ReportPrice`;

    const response = await this.httpService.post<FuelServerResponse<Date>>(
      url,
      price
    );

    if (response.isError || !response.data) {
      return { isError: true, IsNotAuthorized: response.IsNotAuthorized };
    }

    const data = response.data;

    if (data.isOk) {
      return { isError: false, data: data.result };
    } else {
      return {
        isError: true,
        error: "Kan ikke rapportere pris. " + (data.errorDescription ?? ""),
      };
    }
  }

  private getReportyType(reportingString: string): ReportingType {
    if (reportingString === "MUST") {
      return ReportingType.Must;
    }

    if (reportingString === "MAY") {
      return ReportingType.May;
    }

    return ReportingType.Can;
  }

  private mapStationDtoToStation(dto: StationDTO): CompetitorStation {
    return {
      ...this.mapBaseStation(dto),
      reportingType: this.getReportyType(dto.reporting),
      chain: dto.chain,
      lastPriceReporterId: dto.lastPriceReporterId,
    };
  }

  private mapStationDtoToUnoxStation(dto: StationDTO): UnoxStation {
    return {
      ...this.mapBaseStation(dto),
      isBonus: dto.chain === "BONUS",
      observation: dto.observation,
    };
  }

  private mapBaseStation(dto: StationDTO): Station {
    return {
      name: dto.stationName,
      address: dto.address,
      stationNumber: dto.stationNumber,
      lastUnleaded: dto.lastPriceUnleaded,
      lastDiesel: dto.lastPriceDiesel,
      updated: new Date(dto.lastPriceTime),
      id: dto.stationCDMID,
    };
  }
}

export enum PageType {
  Home = "Home",
  PriceReporting = "PriceReporting",
  MyStations = "MyStations",
  Prisspottere = "Prisspottere",
  Ferieafloesere = "Ferieafloesere",
  UserProfile = "UserProfile",
  ChangeProfilePicture = "ChangeProfilePicture",
  Contacts = "Contacts",
}

export enum PagePath {
  Home = "/",
  Login = "/login",
  ForgotPassword = "/forgotpassword",
  PriceReporting = "/prisindrapportering",
  MyStations = "/minestationer",
  Prisspottere = "/prisspottere",
  Ferieafloesere = "/ferieafloesere",
  UserProfile = "/minprofil",
  ChangeProfilePicture = "/skiftprofilbillede",
  Contacts = "/ringrigtigt",
}

import AuthTokenResponse from "../Models/DTO/AuthTokenResponse";
import IAuthService from "./IAuthService";
import IAuthApiService from "./API/IAuthApiService";
import { User } from "../Models/User";

export class AuthService implements IAuthService {
  constructor(private api: IAuthApiService) {}
  restoreUser(): User | null {
    const userText = localStorage.getItem("user");
    if(!userText){
      return null;
    }

    const user = JSON.parse(userText) as User;
    return user;
  }
  storeUser(user: User): void {
    localStorage.setItem("user",JSON.stringify(user));
  }

  async loadToken(userName: string, password: string): Promise<boolean> {
    const tokens = await this.api.getToken(userName, password);

    if (tokens.isError) {
      return false;
    }

    if(tokens.data){
      this.storeTokens(tokens.data);
      return true;
    }
    return false;
  }

  async refreshToken(): Promise<boolean> {
    const token = localStorage.getItem("refresh_token");
    if (!token) {
      return false;
    }

    const newTokens = await this.api.refreshToken(token);

    if (newTokens.isError) {
      return false;
    }

    if(newTokens.data){
      this.storeTokens(newTokens.data);
      return true;
    }
    return false;
  }

  getAuthHeaders(): HeadersInit {
    const token = localStorage.getItem("token");
    return { Authorization: `Bearer ${token}` };
  }

  logOff(){
    localStorage.removeItem("token");
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("user");
  }

  private storeTokens(tokens: AuthTokenResponse) {
    localStorage.setItem("token", tokens.AccessToken);
    localStorage.setItem("refresh_token", tokens.RefreshToken);
  }
}

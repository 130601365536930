import React from "react";
import { Dispatch, AnyAction, bindActionCreators } from "redux";
import { connect, ConnectedProps } from "react-redux";
import {
  Button,
  Grid,
  makeStyles,
  Snackbar,
  TextField,
} from "@material-ui/core";
import { useForm } from "react-hook-form";
import { Alert } from "@material-ui/lab";
import { useHistory } from "react-router-dom";

import { ApplicationState } from "../../store/Store";
import BackImage from "../../assets/images/BackgroundPhotoVertical.jpg";
import { UnoxColors } from "../../constants/theme";
import { PagePath } from "../../Models/PageType";
import { loginUser } from "../../store/user/actionCreators";
import LoadingOverlay from "../../components/LoadingOverlay";
import { clearAlert } from "../../store/system/actionCreators";

const useStyles = makeStyles({
  paperContainer: {
    position: "fixed",
    left: 0,
    height: "100%",
    width: "100%",
    zIndex: 3,
    overflow: "hidden",
    borderRadius: 0,
  },
  picture: {
    objectFit: "cover",
    position: "fixed",
    left: 0,
    height: "100%",
    width: "100%",
    zIndex: 2,
  },
  cssLabel: {
    color: UnoxColors.White,
  },
  cssInput: {
    color: UnoxColors.White,
  },
  notchedOutline: {
    borderWidth: "1px",
    borderColor: UnoxColors.White + "!important",
  },
  marginedComponent: {
    marginTop: 5,
    marginBottom: 5,
  },
  formContainer: {
    paddingLeft: 50,
    paddingRight: 50,
    marginTop: 250,
    maxWidth: 400,
  },
  "@media (max-height: 440px)": {
    formContainer: {
      marginTop: 20,
    },
  },
});

type LoginFormData = {
  UserName: string;
  Password: string;
};

const LoginPage: React.FC<LoginProps> = (props: LoginProps) => {
  const classes = useStyles();
  const history = useHistory();

  const formMethods = useForm<LoginFormData>({ defaultValues: {} });

  const { register, handleSubmit, errors } = formMethods;

  const submit = async (data: LoginFormData) => {
    const success = !!(await props.loginUser(data.UserName, data.Password));
    if (success) {
      history.push(PagePath.Home);
    }
  };

  return (
    <>
      <img src={BackImage} className={classes.picture} alt="Login background" />
      <div className={classes.paperContainer}>
        <form
          onSubmit={handleSubmit(submit)}
          style={{ justifyContent: "center", display: "flex" }}
        >
          <Grid container direction="column" className={classes.formContainer}>
            <TextField
              variant="outlined"
              label="Brugernavn"
              name="UserName"
              size="small"
              color="secondary"
              InputLabelProps={{
                classes: {
                  root: classes.cssLabel,
                },
              }}
              InputProps={{
                classes: {
                  notchedOutline: classes.notchedOutline,
                  root: classes.cssInput,
                },
              }}
              error={!!errors.UserName}
              inputRef={register({
                required: true,
              })}
              className={classes.marginedComponent}
            />
            <TextField
              variant="outlined"
              label="Kodeord"
              name="Password"
              size="small"
              color="secondary"
              type="password"
              InputLabelProps={{
                classes: {
                  root: classes.cssLabel,
                },
              }}
              InputProps={{
                classes: {
                  notchedOutline: classes.notchedOutline,
                  root: classes.cssInput,
                },
              }}
              error={!!errors.Password}
              inputRef={register({
                required: true,
              })}
              className={classes.marginedComponent}
            />
            <Button
              color="primary"
              variant="contained"
              className={classes.marginedComponent}
              type="submit"
            >
              Log ind
            </Button>
            <Button
              color="primary"
              variant="contained"
              className={classes.marginedComponent}
              onClick={() => history.push(PagePath.ForgotPassword)}
            >
              Glemt kodeord?
            </Button>
            <Snackbar
              autoHideDuration={parseInt(
                process.env.REACT_APP_ALERT_TIMEOUT || "0"
              )}
              open={!!props.alert}
              onClose={() => props.clearAlert()}
            >
              <Alert severity={props.alert?.type}>{props.alert?.text}</Alert>
            </Snackbar>
          </Grid>
        </form>
        <Grid style={{ height: 200 }} />
      </div>
      <LoadingOverlay loading={props.loading} />
    </>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  loading: state.system.loading,
  alert: state.system.alert,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      loginUser,
      clearAlert,
    },
    dispatch
  );

const connector = connect(mapStateToProps, mapDispatchToProps);

type LoginProps = ConnectedProps<typeof connector>;

export default connector(LoginPage);

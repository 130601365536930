import React, { useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Collapse,
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import { ExpandMore, Edit } from "@material-ui/icons";

import { UnoxColors } from "../../../constants/theme";
import { Prisspotter } from "../../../Models/Prisspotter";
import EditPrisspotter from "./EditPrisspotter";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    accordionHeader: {
      "&.Mui-expanded": {
        backgroundColor: UnoxColors.Orange,
      },
    },
  })
);

type PrisspotterAccordionProps = {
  prisspotter: Prisspotter;
  uniqueKey: number;
  permissionsSummary: string;
  expandedKey: number | null;
  setExpandedKey: (key: number | null) => void;
  editPrisspotter: (
    actorNumber: number,
    password: string,
  ) => void;
};

const PrisspotterAccordionWrapper: React.FC<PrisspotterAccordionProps> = (
  props
) => {
  const classes = useStyles();

  const [showActorEditForm, setShowActorEditForm] = useState<boolean>(false);

  return (
    <Accordion
      style={{ width: "100%" }}
      expanded={props.expandedKey === props.uniqueKey}
      onChange={(_: object, expanded: boolean) =>
        props.setExpandedKey(expanded ? props.uniqueKey : null)
      }
      key={props.uniqueKey}
    >
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="panel1a-content"
        className={classes.accordionHeader}
      >
        <Grid container direction="row">
          <span
            style={{
              marginRight: 5,
              color: props.prisspotter.permissions?.length
                ? UnoxColors.Green
                : UnoxColors.Red,
            }}
          >
            &#9679;
          </span>
          <img
              src={`${process.env.REACT_APP_SERVICE}api/file/GetProfileImage?actorId=${props.prisspotter.actorNumber}`}
              style={{
                width: 35,
                height: 35,
                objectFit: "contain",
                marginRight: 5,
              }}
              alt="profilbillede"
            />
          <Grid container direction="column" style={{ width: "auto" }}>
            <Typography variant="body2" style={{ fontWeight: "bold" }}>
              {props.prisspotter.name}
            </Typography>
            <Typography variant="body2" style={{letterSpacing: -1.1}}>{props.permissionsSummary}</Typography>
            <Typography variant="body2" >ID: {props.prisspotter.actorNumber}</Typography>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container direction="column">
          <Grid
            container
            direction="row"
            justify="flex-end"
            style={{ alignItems: "center" }}
          >
            <Button
              onClick={() => setShowActorEditForm(true)}
              size="small"
              startIcon={<Edit />}
            >
              Redigér profil
            </Button>
          </Grid>
          <Collapse in={showActorEditForm} onEntered={() => {}}>
            <EditPrisspotter
              prisspotter={props.prisspotter}
              cancel={() => setShowActorEditForm(false)}
              editPrisspotter ={(
                actorNumber: number,
                password: string,
              ) => {
                props.editPrisspotter(actorNumber, password);
                setShowActorEditForm(false);
              }}
            ></EditPrisspotter>
          </Collapse>

          {!showActorEditForm && props.children}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default PrisspotterAccordionWrapper;

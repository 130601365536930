import React from "react";
import {
  Button,
  Grid,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useForm } from "react-hook-form";

import { UnoxColors } from "../../../constants/theme";
import { passwordRegexp } from "../../../services/Validation.patterns";

const useStyles = makeStyles({
  passwordRequirement: {
    marginRight: 15,
  },
});

type ChangePasswordProps = {
  updatePassword: (oldPassword: string, newPassword: string) => void;
};

type ChangePasswordFormData = {
  OldPassword: string;
  NewPassword: string;
  NewPasswordConfirm: string;
};

const ChangePassword: React.FC<ChangePasswordProps> = (props) => {
  const classes = useStyles();

  const formMethods = useForm<ChangePasswordFormData>({ defaultValues: {} });

  const { register, handleSubmit, errors, getValues, reset } = formMethods;

  const submit = async (data: ChangePasswordFormData) => {
    if (data.NewPasswordConfirm !== data.NewPassword) {
      return;
    }
    if (data.OldPassword === data.NewPassword) {
      return;
    }
    props.updatePassword(data.OldPassword, data.NewPassword);
    reset();
  };

  const validateNewPassword = (value: any) => {
    const oldPassword = getValues().OldPassword;
    if (value !== oldPassword) {
      return true;
    } else {
      return "Nyt kodeord skal ikke være den samme som det gamle kodeord.";
    }
  };

  const validateConfirmPassword = (value: any) => {
    const newPassword = getValues().NewPassword;
    if (value === newPassword) {
      return true;
    } else {
      return "Bekræftet kodeord skal være den samme som nyt kodeord.";
    }
  };

  const PasswordRequirementText: React.FC<{}> = ({ children }) => {
    return (
      <Typography
        variant="body2"
        className={classes.passwordRequirement}
        style={errors.NewPassword ? { color: UnoxColors.Red } : {}}
      >
        {children}
      </Typography>
    );
  };

  return (
    <Grid container direction="column">
     <Typography variant="body1" style={{ marginBottom: 10 }}>
          Skift kodeord
        </Typography>
      <form
        onSubmit={handleSubmit(submit)}
        style={{ width: "100%", maxWidth: 400 }}
      >
        <Grid item container style={{ marginBottom: 10, alignItems: "center" }}>
          <Typography variant="body2" style={{ fontWeight: "bold", flex: 1 }}>
            Gammelt kodeord:
            {errors.OldPassword?.message}
          </Typography>

          <TextField
            name="OldPassword"
            size="small"
            color="secondary"
            type="password"
            error={!!errors.OldPassword}
            inputRef={register({
              required: true,
            })}
            style={{ flex: 1 }}
          />
        </Grid>

        <Grid item container style={{ marginBottom: 10, alignItems: "center" }}>
          <Typography variant="body2" style={{ fontWeight: "bold", flex: 1 }}>
            Nyt kodeord:
          </Typography>

          <TextField
            name="NewPassword"
            size="small"
            color="secondary"
            type="password"
            error={!!errors.NewPassword}
            inputRef={register({
              required: true,
              pattern: passwordRegexp,
              validate: validateNewPassword,
            })}
            style={{ flex: 1 }}
          />
        </Grid>

        <Grid item container direction="row">
          <PasswordRequirementText>
            Mindst et lille bogstav
          </PasswordRequirementText>
          <PasswordRequirementText>
            Mindst et stort bogstav
          </PasswordRequirementText>
          <PasswordRequirementText>Mindst et tal</PasswordRequirementText>
          <PasswordRequirementText>Mindst 8 tegn</PasswordRequirementText>
          <PasswordRequirementText>
            Kun tal og bogstaver
          </PasswordRequirementText>
        </Grid>

        <Grid item container style={{ marginBottom: 10, alignItems: "center" }}>
          <Typography variant="body2" style={{ fontWeight: "bold", flex: 1 }}>
            Bekræft nyt kodeord:
          </Typography>

          <TextField
            name="NewPasswordConfirm"
            size="small"
            color="secondary"
            type="password"
            error={!!errors.NewPasswordConfirm}
            inputRef={register({
              required: true,
              pattern: passwordRegexp,
              validate: validateConfirmPassword,
            })}
            style={{ flex: 1 }}
          />
        </Grid>
        {errors.NewPassword && errors.NewPassword.message && (
          <Alert severity="error">{errors.NewPassword.message}</Alert>
        )}
        {errors.NewPasswordConfirm && errors.NewPasswordConfirm.message && (
          <Alert severity="error">{errors.NewPasswordConfirm.message}</Alert>
        )}

        <Grid item container justify="center">
          <Button
            style={{
              backgroundColor: UnoxColors.Green,
              color: UnoxColors.White,
            }}
            variant="contained"
            type="submit"
          >
            Gem kodeord
          </Button>
        </Grid>
      </form>
    </Grid>
  );
};

export default ChangePassword;

import React from "react";
import { Grid } from "@material-ui/core";

import {
  Ferieafloeser,
  FerieafloeserPermissions,
} from "../../../Models/Ferieafloeser";
import FerieafloeserPermissionsComponent from "./FerieafloeserPermissionsComponent";
import FerieafloeserAccordionWrapper from "./FerieafloeserAccordionWrapper";
import { getShortDateString } from "../../../services/DateFormatter";

type FerieafloeserListProps = {
  ferieafloesers: Ferieafloeser[];

  expandedActor: number | null;
  setExpandedActor: (actorNumber: number | null) => void;
  editFefieafloeser: (
    actorNumber: number,
    password: string,
    email: string,
    phone: string
  ) => void;
  removePermissions: (actorNumber: number, permissions: FerieafloeserPermissions) => void;
};

const FerieafloeserPermissionList: React.FC<FerieafloeserListProps> = (
  props
) => {
  const getPermissionSummary = (permissions: FerieafloeserPermissions) => {
    const firstPart = `${getShortDateString(permissions.startDate)} - ${getShortDateString(permissions.endDate)} `;
    if (permissions.stationPermissions.length === 1) {
      return firstPart + "(1 station)";
    }
    return firstPart + `(${permissions.stationPermissions.length} stationer)`;
  };

  const getUniqueKey = (
    ferieafloeser: Ferieafloeser,
    permissionIndex: number
  ) => ferieafloeser.actorNumber + (permissionIndex + 1) * 10000;

  return (
    <Grid container justify="center">
      {props.ferieafloesers.map((ferieafloeser: Ferieafloeser) => (
        <Grid container key={ferieafloeser.actorNumber}>
          {ferieafloeser.permissions &&
            ferieafloeser.permissions.map(
              (permissions: FerieafloeserPermissions, index: number) => (
                <FerieafloeserAccordionWrapper
                  ferieafloeser={ferieafloeser}
                  uniqueKey={getUniqueKey(ferieafloeser, index)}
                  key={getUniqueKey(ferieafloeser, index)}
                  expandedKey={props.expandedActor}
                  permissionsSummary={getPermissionSummary(permissions)}
                  setExpandedKey={props.setExpandedActor}
                  editFefieafloeser={(
                    actorNumber: number,
                    password: string,
                    email: string,
                    phone: string
                  ) =>
                    props.editFefieafloeser(actorNumber, password, email, phone)
                  }
                >
                  <FerieafloeserPermissionsComponent
                    permissions={permissions}
                    removePermissions={() =>
                      props.removePermissions(ferieafloeser.actorNumber, permissions)
                    }
                  ></FerieafloeserPermissionsComponent>
                </FerieafloeserAccordionWrapper>
              )
            )}
        </Grid>
      ))}
    </Grid>
  );
};

export default FerieafloeserPermissionList;

import React from "react";
import { Dispatch, AnyAction, bindActionCreators } from "redux";
import { connect, ConnectedProps } from "react-redux";

import { restoreUser } from "../store/user/actionCreators";
import { restoreLocalSettings } from "../store/system/actionCreators";
import { ApplicationState } from "../store/Store";

const AppDataLoader: React.FC<AppDataLoaderConnectedProps> = ({
  restoreUser,
  restoreLocalSettings,
}) => {
  restoreUser();
  restoreLocalSettings();
  return <></>;
};

const mapStateToProps = (state: ApplicationState) => ({});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      restoreUser,
      restoreLocalSettings,
    },
    dispatch
  );

const connector = connect(mapStateToProps, mapDispatchToProps);

type AppDataLoaderConnectedProps = ConnectedProps<typeof connector>;

export default connector(AppDataLoader);

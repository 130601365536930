import { AppAction } from "./../Models/Action";
import { IServices } from "./Store";
import { LOGOFF_COMPLETE } from "./user/actions";
import { ALERT } from './system/actions';
import ApiResponse from "../Models/ApiResponse";

export function dispatchError(error: any, dispatch: (value: AppAction) => void) {
  const errorText = typeof error === "string"? error: "En fejl opstod";
  dispatch({
    type: ALERT,
    payload: { text: errorText, type: "error" },
  });
}

export function handleError<T>(
  result: ApiResponse<T>,
  services: IServices,
  dispatch: (value: AppAction) => void
) {
  if (result.IsNotAuthorized) {
    services.authService.logOff();
    dispatch({
      type: LOGOFF_COMPLETE,
    });
  } else {
    dispatch({
      type: ALERT,
      payload: { text: result.error, type: "error" },
    });
  }
}
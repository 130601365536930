import { StationPermission } from "../../Models/StationPermission";
import { Prisspotter } from "./../../Models/Prisspotter";
import { LOGOFF_COMPLETE, UserActionTypes } from '../user/actions';

import {
  PrisspotterActionTypes,
  GET_PRISSPOTTERS_COMPLETE,
  REMOVE_PRISSPOTTERS_COMPLETE,
  REMOVE_PRISSPOTTER_PERMISSIONS_COMPLETE,
} from "./actions";

export interface PrisspotterState {
  Prisspotters: Prisspotter[];
  permissionStations: StationPermission[];
}

export const PrisspotterInitialState: PrisspotterState = {
  Prisspotters: [],
  permissionStations: [],
};

const PrisspotterReducer = (
  state = PrisspotterInitialState,
  action: PrisspotterActionTypes | UserActionTypes
): PrisspotterState => {
  switch (action.type) {
    case GET_PRISSPOTTERS_COMPLETE: {
      return {
        ...state,
        Prisspotters: action.payload.prisspotters,
        permissionStations: action.payload.permissionStations,
      };
    }

    case REMOVE_PRISSPOTTERS_COMPLETE: {
      return {
        ...state,
        Prisspotters: state.Prisspotters.filter(
          (actor) => actor.actorNumber !== action.payload
        ),
      };
    }
    case REMOVE_PRISSPOTTER_PERMISSIONS_COMPLETE: {
      return {
        ...state,
        Prisspotters: state.Prisspotters.map((actor) =>
          actor.actorNumber === action.payload
            ? { ...actor, permissions: [] }
            : actor
        ),
      };
    }
    case LOGOFF_COMPLETE: {
      return PrisspotterInitialState;
    }
    default:
      return state;
  }
};

export default PrisspotterReducer;

import React from "react";
import { CompetitorStation } from "../../../Models/Station";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import { UnoxColors } from "../../../constants/theme";
import CompetitorPriceReporting from "./CompetitorPriceReporting";
import { getLongDateString } from "../../../services/DateFormatter";
import { Alert } from "@material-ui/lab";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    accordionHeader: {
      "&.Mui-expanded": {
        backgroundColor: UnoxColors.Orange,
      },
    },
  })
);

type CompetitorStationsListProps = {
  stations: CompetitorStation[];
  expandedStationId: string | null;
  successfullyReportedStationId: string | null;
  onSubmit: (
    unleadedPrice: number,
    diselPrice: number,
    stationId: string
  ) => void;
  expandCompetitorStation: (stationId: string | null) => void;
};

const CompetitorStationsList: React.FC<CompetitorStationsListProps> = (
  props
) => {
  const classes = useStyles();

  return (
    <>
      <Grid container justify="center">
        {props.stations.map((station: CompetitorStation) => (
          <Accordion
            key={station.stationNumber}
            style={{ width: "100%" }}
            expanded={props.expandedStationId === station.id}
            onChange={(_: object, expanded: boolean) =>
              props.expandCompetitorStation(expanded ? station.id : null)
            }
          >
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel1a-content"
              className={classes.accordionHeader}
            >
              <Grid item container direction="row">
                <Grid item container direction="column" style={{ flex: 1 }}>
                  <Grid item>
                    <Typography
                      variant="subtitle2"
                      style={{ fontWeight: "bold" }}
                    >
                      {station.name}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="body2">{station.address}</Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="body2">
                      {getLongDateString(station.updated)}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  item
                  container
                  direction="column"
                  style={{ width: "auto" }}
                >
                  <Grid item>
                    <Typography variant="body2" style={{ fontWeight: "bold" }}>
                      ID: {station.lastPriceReporterId}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="body2" style={{ fontWeight: "bold" }}>
                      B:{" "}
                      <span
                        style={
                          station.lastUnleaded < 10 ? { marginLeft: "1ch" } : {}
                        }
                      >
                        {station.lastUnleaded?.toFixed(2)}
                      </span>
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="body2" style={{ fontWeight: "bold" }}>
                      D:{" "}
                      <span
                        style={
                          station.lastDiesel < 10 ? { marginLeft: "1ch" } : {}
                        }
                      >
                        {station.lastDiesel?.toFixed(2)}
                      </span>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails style={{ marginLeft: 20, marginRight: 20 }}>
              <Grid container direction="column">
                <CompetitorPriceReporting
                  station={station}
                  onSubmit={(unleadedPrice: number, dieselPrice: number) => {
                    props.onSubmit(unleadedPrice, dieselPrice, station.id);
                  }}
                ></CompetitorPriceReporting>
                {station.id === props.successfullyReportedStationId && (
                  <Alert severity="success">
                    <Typography variant="body1">
                      Succesfuld indberetning!
                    </Typography>
                    <Typography variant="body2">
                      Blyfri: {station.lastUnleaded?.toFixed(2)} Diesel:{" "}
                      {station.lastDiesel?.toFixed(2)}
                    </Typography>
                  </Alert>
                )}
              </Grid>
            </AccordionDetails>
          </Accordion>
        ))}
      </Grid>
    </>
  );
};

export default CompetitorStationsList;

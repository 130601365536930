import ApiResponse from "../../Models/ApiResponse";
import IAuthService from "../IAuthService";
import IHttpService from "./IHttpService";

export class HttpService implements IHttpService {
  private waitForPendingRefreshToken: Promise<void> = Promise.resolve();
  private isRefreshPending: boolean = false;

  constructor(private auth: IAuthService) {}

  get<T>(
    url: string,
    headers?: HeadersInit,
    isAnon?: boolean
  ): Promise<ApiResponse<T>> {
    return this.processRequest<T>("GET", url, null, headers, isAnon);
  }

  post<T>(
    url: string,
    body: Record<string, any> | null,
    headers?: HeadersInit,
    isAnon?: boolean
  ): Promise<ApiResponse<T>> {
    return this.processRequest<T>("POST", url, body, headers, isAnon);
  }

  private async processRequest<T>(
    method: "GET" | "POST",
    url: string,
    body: Record<string, any> | null,
    headers?: HeadersInit,
    isAnon?: boolean
  ): Promise<ApiResponse<T>> {
    try {
      const getResponse = async () => {
        const headersToSend: any = this.getHeaders(isAnon, headers);
        let bodyToSend: any = null;
        if (body) {
          if (headersToSend["Content-Type"] === "application/json") {
            bodyToSend = JSON.stringify(body);
          } else {
            bodyToSend = body;
          }
        }

        return await fetch(url, {
          method: method,
          headers: headersToSend,
          mode: "cors",
          body: bodyToSend,
        });
      };

      let response = await getResponse();

      if (response.status === 401) {
        // try to refresh auth token

        if (this.isRefreshPending) {
          // don't refresh token twice
          await this.waitForPendingRefreshToken;
          response = await getResponse();
        } else {
          this.isRefreshPending = true;
          this.waitForPendingRefreshToken = new Promise<void>(
            async (resolve) => {
              const refreshSuccess = await this.auth.refreshToken();
              if (refreshSuccess) {
                response = await getResponse();
              }
              this.isRefreshPending = false;
              resolve();
            }
          );
          
          await this.waitForPendingRefreshToken;
        }
      }

      if (response.status >= 400) {
        return {
          isError: true,
          IsNotAuthorized: response.status === 401,
        };
      }
      const data: T = await response.json();
      return { isError: false, data: data };
    } catch (error) {
      return { isError: true, error: error };
    }
  }

  private getHeaders(
    isAnon?: boolean,
    customHeaders?: HeadersInit
  ): HeadersInit {
    const defaultHeaders = {
      ...(isAnon ? {} : this.auth.getAuthHeaders()),
      "Content-Type": "application/json",
    };

    let result: any = { ...defaultHeaders, ...customHeaders };

    for (let header in result) {
      if (result[header] === "") {
        delete result[header];
      }
    }

    return result;
  }
}

import { Contact } from "../../Models/Contact";

export const GET_CONTACTS_COMPLETE = "GET_CONTACTS_COMPLETE";

interface GetContactsCompleteAction {
  type: typeof GET_CONTACTS_COMPLETE;
  payload: Contact[];
}

export type ContactActionTypes =
  | GetContactsCompleteAction;
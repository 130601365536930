import { Dispatch } from "react";
import { AppAction } from "../../Models/Action";
import { IServices } from "../Store";
import {
  EDIT_FERIEAFLOESER_COMPLETE,
  GET_FERIEAFLOESERS_COMPLETE,
  REMOVE_FERIEAFLOESER_COMPLETE,
} from "./actions";
import { SHOW_SPINNER } from "../system/actions";
import ApiResponse from "../../Models/ApiResponse";
import {
  Ferieafloeser,
  FerieafloeserPermissions,
} from "../../Models/Ferieafloeser";
import GetFerieafloesersResponse from "../../Models/DTO/GetFerieafloesersResponse";
import { dispatchError, handleError } from "../actionCreatorsHelper";

export const getFerieafloesers = () => async (
  dispatch: Dispatch<AppAction>,
  getState: any,
  services: IServices
) => {
  try {
    dispatch({
      type: SHOW_SPINNER,
    });
    const response = await services.subordinateUsersApiService.getFerieafloesers();
    const onSuccess = (data: GetFerieafloesersResponse) => {
      dispatch({
        type: GET_FERIEAFLOESERS_COMPLETE,
        payload: data,
      });
    };
    processResponse(response, services, dispatch, onSuccess);
  } catch (error) {
    dispatchError(error, dispatch);
  }
};

export const createFerieafloese = (
  actor: Ferieafloeser,
  password: string
) => async (
  dispatch: Dispatch<AppAction>,
  getState: any,
  services: IServices
) => {
  try {
    dispatch({
      type: SHOW_SPINNER,
    });
    const response = await services.subordinateUsersApiService.createFerieafloeser(
      actor,
      password
    );
    const onSuccess = () => {
      getFerieafloesers()(dispatch, getState, services);
    };
    processResponse(response, services, dispatch, onSuccess);
  } catch (error) {
    dispatchError(error, dispatch);
  }
};

export const editFefieafloeser = (
  actorNumber: number,
  password: string,
  email: string,
  phone: string
) => async (
  dispatch: Dispatch<AppAction>,
  getState: any,
  services: IServices
) => {
  try {
    dispatch({
      type: SHOW_SPINNER,
    });
    const response = await services.subordinateUsersApiService.editFefieafloeser(
      actorNumber,
      password,
      email,
      phone
    );
    const onSuccess = () => {
      dispatch({
        type: EDIT_FERIEAFLOESER_COMPLETE,
        payload: { actorNumber, email, phone },
      });
    };
    processResponse(response, services, dispatch, onSuccess);
  } catch (error) {
    dispatchError(error, dispatch);
  }
};

export const removeFerieafloeser = (actorNumber: number) => async (
  dispatch: Dispatch<AppAction>,
  getState: any,
  services: IServices
) => {
  try {
    dispatch({
      type: SHOW_SPINNER,
    });
    const response = await services.subordinateUsersApiService.removeSubordinateUser(
      actorNumber
    );
    const onSuccess = () => {
      dispatch({
        type: REMOVE_FERIEAFLOESER_COMPLETE,
        payload: actorNumber,
      });
    };
    processResponse(response, services, dispatch, onSuccess);
  } catch (error) {
    dispatchError(error, dispatch);
  }
};

export const changePermissions = (
  actorNumber: number,
  permissions: FerieafloeserPermissions
) => async (
  dispatch: Dispatch<AppAction>,
  getState: any,
  services: IServices
) => {
  try {
    dispatch({
      type: SHOW_SPINNER,
    });
    const stationNumbers = permissions.stationPermissions.map(
      (st) => st.stationNumber
    );

    const response = await services.subordinateUsersApiService.addPermissions(
      actorNumber,
      permissions.startDate,
      permissions.endDate,
      stationNumbers
    );
    const onSuccess = (data: any) => {
      getFerieafloesers()(dispatch, getState, services);
    };
    processResponse(response, services, dispatch, onSuccess);
  } catch (error) {
    dispatchError(error, dispatch);
  }
};

export const removePermissions = (
  actorNumber: number,
  permissions: FerieafloeserPermissions
) => async (
  dispatch: Dispatch<AppAction>,
  getState: any,
  services: IServices
) => {
  try {
    dispatch({
      type: SHOW_SPINNER,
    });

    const agreementNumbers = permissions.stationPermissions.map(
      (st) => st.agreementId
    );
    const response = await services.subordinateUsersApiService.removePermissions(
      actorNumber,
      agreementNumbers
    );
    const onSuccess = () => {
      getFerieafloesers()(dispatch, getState, services);
      /* dispatch({
        type: REMOVE_FERIEAFLOESER_PERMISSIONS_COMPLETE,
        payload: actorNumber,
      });*/
    };
    processResponse(response, services, dispatch, onSuccess);
  } catch (error) {
    dispatchError(error, dispatch);
  }
};

function processResponse<T>(
  response: ApiResponse<T>,
  services: IServices,
  dispatch: Dispatch<AppAction>,
  onsuccess: (data: T) => void
) {
  if (response.isError) {
    handleError(response, services, dispatch);
  } else {
    onsuccess(response.data!);
  }
}

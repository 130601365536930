import { User } from "../../Models/User";
import { ChangeContactInfoRequest } from '../../Models/DTO/ChangeContactInfoRequest';

//export const LOGIN = "LOGIN";
export const LOGIN_COMPLETE = "LOGIN_COMPLETE";
export const LOGOFF_COMPLETE = "LOGOFF_COMPLETE";
export const CHANGE_CONTACT_INFO = "CHANGE_CONTACT_INFO";
export const CHANGE_CONTACT_INFO_COMPLETE = "CHANGE_CONTACT_INFO_COMPLETE";
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const CHANGE_PASSWORD_COMPLETE = "CHANGE_PASSWORD_COMPLETE";
export const CHANGE_AVATAR = "CHANGE_AVATAR";
export const CHANGE_AVATAR_COMPLETE = "CHANGE_AVATAR_COMPLETE";

/*
interface LoginAction {
  type: typeof LOGIN;
  payload: {username:string, password: string};
}*/

interface LoginCompleteAction {
  type: typeof LOGIN_COMPLETE;
  payload: User;
}

interface LogoffCompleteAction{
  type: typeof LOGOFF_COMPLETE;
}

interface ChangeContactInfoAction{
  type: typeof CHANGE_CONTACT_INFO;
}

interface ChangeContactInfoCompleteAction{
  type: typeof CHANGE_CONTACT_INFO_COMPLETE;
  payload: ChangeContactInfoRequest;
}

interface ChangePasswordAction {
  type: typeof CHANGE_PASSWORD;
}

interface ChangePasswordCompleteAction {
  type: typeof CHANGE_PASSWORD_COMPLETE;
}

interface ChangeAvatarAction {
  type: typeof CHANGE_AVATAR;
}

interface ChangeAvatarCompleteAction {
  type: typeof CHANGE_AVATAR_COMPLETE;
}

export type UserActionTypes =
  //| LoginAction
  | LoginCompleteAction
  | LogoffCompleteAction
  | ChangeContactInfoAction
  | ChangeContactInfoCompleteAction
  | ChangePasswordAction
  | ChangePasswordCompleteAction
  | ChangeAvatarAction
  | ChangeAvatarCompleteAction;
  
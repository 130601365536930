import React from "react";
import { Dispatch, AnyAction, bindActionCreators } from "redux";
import { connect, ConnectedProps } from "react-redux";
import { Route, RouteProps } from "react-router-dom";

import { ApplicationState } from "../store/Store";
import LoginPage from "./../pages/Login/LoginPage";

const ProtectedRoute: React.FC<ProtectedRouteConnectedProps> = ({
  isLoggedIn,
  component,
  ...rest
}) => {
  return <Route component={isLoggedIn ? component : LoginPage} {...rest}/>;
};

const mapStateToProps = (state: ApplicationState) => ({
  isLoggedIn: !!state.user.currentUser,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators({}, dispatch);

const connector = connect(mapStateToProps, mapDispatchToProps);

type ProtectedRouteConnectedProps = ConnectedProps<typeof connector> &
  RouteProps;

export default connector(ProtectedRoute);

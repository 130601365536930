import IHttpService from "./IHttpService";
import ApiResponse from "../../Models/ApiResponse";
import FuelServerResponse from "../../Models/DTO/FuelServerResponse";
import IContactApiService from "./IContactApiService";
import { Contact } from "../../Models/Contact";
  
export class ContactApiService implements IContactApiService {
  constructor(private httpService: IHttpService) {}

  async getContacts(): Promise<ApiResponse<Contact[]>> {
    const url = `${process.env.REACT_APP_SERVICE}api/report/GetUnoxnetContacts`;
    const response = await this.httpService.get<
      FuelServerResponse<Contact[]>
    >(url);

    if (response.isError || !response.data) {
      return { isError: true, IsNotAuthorized: response.IsNotAuthorized };
    }

    const data = response.data;
    if (data.isOk) {
      return { isError: false, data: data.result };
    } else {
      return {
        isError: true,
        error:
          "Kan ikke indlæse kontakter. " + (data.errorDescription ?? ""),
      };
    }
  }
}

import React from "react";
import { Button, Grid, TextField, Typography } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { useForm } from "react-hook-form";

import { UnoxColors } from "../../../constants/theme";
import { Prisspotter } from "../../../Models/Prisspotter";
import {
  passwordRegexp,
} from "../../../services/Validation.patterns";

type EditPrisspotterProps = {
  prisspotter: Prisspotter;
  editPrisspotter: (
    actorNumber: number,
    password: string,
  ) => void;
  cancel: () => void;
};

type EditPrisspotterFormData = {
  Password: string;
  PasswordConfirm: string;
};

const EditPrisspotter: React.FC<EditPrisspotterProps> = (props) => {
  const formMethods = useForm<EditPrisspotterFormData>({
    defaultValues: {},
  });

  const { register, handleSubmit, errors, getValues } = formMethods;

  const submit = async (data: EditPrisspotterFormData) => {
    props.editPrisspotter(
      props.prisspotter.actorNumber,
      data.Password,
    );
  };

  const PasswordRequirementText: React.FC<{}> = ({ children }) => {
    return (
      <Typography
        variant="body2"
        style={
          errors.Password ? { color: UnoxColors.Red, marginRight: 15 } : {}
        }
      >
        {children}
      </Typography>
    );
  };

  const validateConfirmPassword = (value: any) => {
    const newPassword = getValues().Password;
    if (value === newPassword) {
      return true;
    } else {
      return "Bekræftet kodeord skal være den samme som kodeord.";
    }
  };

  return (
    <>
      <Typography variant="h5" style={{ marginBottom: 10, marginTop: 10 }}>
        Rediger ferieafløser
      </Typography>

      <form
        onSubmit={handleSubmit(submit)}
        style={{ width: "100%", maxWidth: 400 }}
      >
        <Grid item container style={{ marginBottom: 10, alignItems: "center" }}>
          <Typography variant="body2" style={{ fontWeight: "bold", flex: 1 }}>
            Navn:
          </Typography>

          <TextField
            name="Name"
            size="small"
            color="secondary"
            value={props.prisspotter.name}
            disabled={true}
            style={{ flex: 1 }}
          />
        </Grid>

        <Grid item container style={{ marginBottom: 10, alignItems: "center" }}>
          <Typography variant="body2" style={{ fontWeight: "bold", flex: 1 }}>
            Brugernavn:
          </Typography>

          <TextField
            name="UserName"
            size="small"
            color="secondary"
            value={props.prisspotter.userName}
            disabled={true}
            style={{ flex: 1 }}
          />
        </Grid>

        <Grid item container style={{ marginBottom: 10, alignItems: "center" }}>
          <Typography variant="body2" style={{ fontWeight: "bold", flex: 1 }}>
            Kodeord:
          </Typography>

          <TextField
            name="Password"
            size="small"
            color="secondary"
            type="password"
            error={!!errors.Password}
            inputRef={register({
              required: true,
              pattern: passwordRegexp,
            })}
            style={{ flex: 1 }}
          />
        </Grid>

        <Grid item container direction="row">
          <PasswordRequirementText>
            Mindst et lille bogstav
          </PasswordRequirementText>
          <PasswordRequirementText>
            Mindst et stort bogstav
          </PasswordRequirementText>
          <PasswordRequirementText>Mindst et tal</PasswordRequirementText>
          <PasswordRequirementText>Mindst 8 tegn</PasswordRequirementText>
          <PasswordRequirementText>
            Kun tal og bogstaver
          </PasswordRequirementText>
        </Grid>

        <Grid item container style={{ marginBottom: 10, alignItems: "center" }}>
          <Typography variant="body2" style={{ fontWeight: "bold", flex: 1 }}>
            Bekræft kodeord:
          </Typography>

          <TextField
            name="PasswordConfirm"
            size="small"
            color="secondary"
            type="password"
            error={!!errors.PasswordConfirm}
            inputRef={register({
              required: true,
              pattern: passwordRegexp,
              validate: validateConfirmPassword,
            })}
            style={{ flex: 1 }}
          />
        </Grid>
        {errors.PasswordConfirm && errors.PasswordConfirm.message && (
          <Alert severity="error">{errors.PasswordConfirm.message}</Alert>
        )}

        <Grid item container direction="row" justify="space-between">
          <Button
            style={{
              backgroundColor: UnoxColors.Red,
              color: UnoxColors.White,
              maxWidth: 160,
              marginRight: 20,
              flex: 1,
            }}
            variant="contained"
            onClick={() => props.cancel()}
          >
            Annuller
          </Button>
          <Button
            style={{
              backgroundColor: UnoxColors.Green,
              color: UnoxColors.White,
              maxWidth: 160,
              flex: 1,
            }}
            variant="contained"
            type="submit"
          >
            Gem
          </Button>
        </Grid>
      </form>
    </>
  );
};

export default EditPrisspotter;

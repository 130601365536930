import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { UnoxColors } from "../constants/theme";

type ConfirmationDialogProps = {
  title?: string;
  message: string;
  open: boolean;
  confirm: () => void;
  cancel: () => void;
};

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = (props) => {
  return (
    <Dialog onClose={() => props.cancel()} open={props.open}>
      <DialogTitle>{props.title ?? "Er du sikker?"}</DialogTitle>
      <DialogContent>
        <DialogContentText>{props.message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          style={{
            backgroundColor: UnoxColors.Red,
            color: UnoxColors.White,
          }}
          onClick={() => props.cancel()}
        >
          Annuller
        </Button>
        <Button
          style={{
            backgroundColor: UnoxColors.Green,
            color: UnoxColors.White,
          }}
          onClick={() => props.confirm()}
          variant="contained"
          autoFocus
        >
          Bekræfte
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;

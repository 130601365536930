import React, { useEffect, useState } from "react";
import { CompetitorStation } from "../../../Models/Station";
import {
  Button,
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { theme, UnoxColors } from "../../../constants/theme";
import PriceTextField from "./PriceTextField";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    submitButton: {
      backgroundColor: UnoxColors.Green,
      color: UnoxColors.White,
      "&.MuiButton-contained:hover": {
        backgroundColor: UnoxColors.Green,
      },
    },
  })
);

type CompetitorPriceReportingProps = {
  station: CompetitorStation;
  onSubmit: (unleadedPrice: number, diselPrice: number) => void;
};

const CompetitorPriceReporting: React.FC<CompetitorPriceReportingProps> = ({
  station,
  onSubmit,
}: CompetitorPriceReportingProps) => {
  const classes = useStyles();
  const isXs = useMediaQuery(theme.breakpoints.down("xs"));
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const switchToNextControl = (currentIndex: number) => {
    setActiveIndex(currentIndex + 1);
  };

  const [unleadedKroner, setUnleadedKroner] = useState<number | string>(0);
  const [unleadedOere, setUnleadedOere] = useState<number | string>(0);
  const [dieselKroner, setDieselKroner] = useState<number | string>(0);
  const [dieselOere, setDieselOere] = useState<number | string>(0);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [activeFieldBeforeFocus, setActiveFieldBeforeFocus] = useState<
    number | string
  >("");

  useEffect(() => {
    setUnleadedKroner(Math.trunc(station.lastUnleaded));
    const uOere = Math.round((station.lastUnleaded % 1) * 100);
    setUnleadedOere(uOere < 10 ? "0" + uOere : uOere);
    setDieselKroner(Math.trunc(station.lastDiesel));
    const dOere = Math.round((station.lastDiesel % 1) * 100);
    setDieselOere(dOere < 10 ? "0" + dOere : dOere);
  }, [station]);

  const onPriceChange = (
    newPricePartValue: number | string,
    pricePartIndex: number,
    valueBeforeFocus?: number | string
  ) => {
    switch (pricePartIndex) {
      case 1:
        setUnleadedKroner(newPricePartValue);
        break;
      case 2:
        setUnleadedOere(newPricePartValue);
        break;
      case 3:
        setDieselKroner(newPricePartValue);
        break;
      case 4:
        setDieselOere(newPricePartValue);
        break;
    }

    //value that is being changed now
    const activeValue = valueBeforeFocus ?? activeFieldBeforeFocus;

    const allValid =
      (pricePartIndex === 1
        ? newPricePartValue !== "" || activeValue !== ""
        : unleadedKroner !== "") &&
      // unleadedKroner, unleadedOere... are not updated yet, so we have an additional check
      (pricePartIndex === 2
        ? newPricePartValue !== "" || activeValue !== ""
        : unleadedOere !== "") &&
      (pricePartIndex === 3
        ? newPricePartValue !== "" || activeValue !== ""
        : dieselKroner !== "number") &&
      (pricePartIndex === 4
        ? newPricePartValue !== "" || activeValue !== ""
        : dieselOere !== "number");

    setIsButtonDisabled(!allValid);
  };

  const onFocusChange = (pricePartIndex: number) => {
    let valueBeforeFocus: number | string = "";
    switch (pricePartIndex) {
      case 1:
        valueBeforeFocus = unleadedKroner;
        break;
      case 2:
        valueBeforeFocus = unleadedOere;
        break;
      case 3:
        valueBeforeFocus = dieselKroner;
        break;
      case 4:
        valueBeforeFocus = dieselOere;
        break;
    }
    setActiveFieldBeforeFocus(valueBeforeFocus);

    setActiveIndex(pricePartIndex);
    onPriceChange("", pricePartIndex, valueBeforeFocus);
  };

  const restoreBeforeInputValueBack = () => {
    let unleadedKronerVal =
      typeof unleadedKroner === "number"
        ? unleadedKroner
        : parseInt(unleadedKroner);
    let unleadedOereVal =
      typeof unleadedOere === "number" ? unleadedOere : parseInt(unleadedOere);
    let dieselKronerVal =
      typeof dieselKroner === "number" ? dieselKroner : parseInt(dieselKroner);
    let dieselOereVal =
      typeof dieselOere === "number" ? dieselOere : parseInt(dieselOere);
    if (
      activeIndex === 1 &&
      unleadedKroner === "" &&
      typeof activeFieldBeforeFocus === "number"
    ) {
      setUnleadedKroner(activeFieldBeforeFocus);
      unleadedKronerVal =
        typeof activeFieldBeforeFocus === "number"
          ? activeFieldBeforeFocus
          : parseInt(activeFieldBeforeFocus);
    }
    if (
      activeIndex === 2 &&
      unleadedOere === "" &&
      typeof activeFieldBeforeFocus === "number"
    ) {
      setUnleadedOere(activeFieldBeforeFocus);
      unleadedOereVal =
        typeof activeFieldBeforeFocus === "number"
          ? activeFieldBeforeFocus
          : parseInt(activeFieldBeforeFocus);
    }
    if (
      activeIndex === 3 &&
      dieselKroner === "" &&
      typeof activeFieldBeforeFocus === "number"
    ) {
      setDieselKroner(activeFieldBeforeFocus);
      dieselKronerVal =
        typeof activeFieldBeforeFocus === "number"
          ? activeFieldBeforeFocus
          : parseInt(activeFieldBeforeFocus);
    }
    if (
      activeIndex >= 4 &&
      dieselOere === "" &&
      activeFieldBeforeFocus !== ""
    ) {
      setDieselOere(activeFieldBeforeFocus);
      dieselOereVal =
        typeof activeFieldBeforeFocus === "number"
          ? activeFieldBeforeFocus
          : parseInt(activeFieldBeforeFocus);
    }
    return {
      unleadedKronerVal,
      unleadedOereVal,
      dieselKronerVal,
      dieselOereVal,
    };
  };

  const onButtonClick = () => {
    //calculate values that take into account current element
    const {
      unleadedKronerVal,
      unleadedOereVal,
      dieselKronerVal,
      dieselOereVal,
    } = restoreBeforeInputValueBack();

    const unleadedPrice =
      (typeof unleadedKronerVal === "number"
        ? unleadedKronerVal
        : parseInt(unleadedKronerVal)) +
      (typeof unleadedOereVal === "number"
        ? unleadedOereVal
        : parseInt(unleadedOereVal)) /
        100.0;
    const dieselPrice =
      (typeof dieselKronerVal === "number"
        ? dieselKronerVal
        : parseInt(dieselKronerVal)) +
      (typeof dieselOereVal === "number"
        ? dieselOereVal
        : parseInt(dieselOereVal)) /
        100.0;

    onSubmit(unleadedPrice, dieselPrice);
  };

  return (
    <Grid container direction="column">
      <Grid item container direction="row" justify="space-around">
        <Typography variant="body2" style={{ fontWeight: "bold" }}>
          Blyfri 95
        </Typography>
        <Typography variant="body2" style={{ fontWeight: "bold" }}>
          Diesel
        </Typography>
      </Grid>
      <Grid
        item
        container
        direction="row"
        justify={isXs ? "space-between" : "space-evenly"}
        style={{ marginTop: 10, marginBottom: 10 }}
      >
        <PriceTextField
          pricePart={unleadedKroner}
          onPriceChange={(val) => onPriceChange(val, 1)}
          isKroner={true}
          switchToNextControl={() => switchToNextControl(1)}
          isFocused={activeIndex === 1}
          onFocus={() => onFocusChange(1)}
        ></PriceTextField>
        <PriceTextField
          pricePart={unleadedOere}
          onPriceChange={(val) => onPriceChange(val, 2)}
          isKroner={false}
          switchToNextControl={() => switchToNextControl(2)}
          isFocused={activeIndex === 2}
          onFocus={() => onFocusChange(2)}
        ></PriceTextField>
        <PriceTextField
          pricePart={dieselKroner}
          onPriceChange={(val) => onPriceChange(val, 3)}
          isKroner={true}
          switchToNextControl={() => switchToNextControl(3)}
          isFocused={activeIndex === 3}
          onFocus={() => onFocusChange(3)}
        ></PriceTextField>
        <PriceTextField
          pricePart={dieselOere}
          onPriceChange={(val) => onPriceChange(val, 4)}
          isKroner={false}
          switchToNextControl={() => switchToNextControl(4)}
          isFocused={activeIndex === 4}
          onFocus={() => onFocusChange(4)}
        ></PriceTextField>
      </Grid>
      <Button
        onClick={() => onButtonClick()}
        variant="contained"
        disabled={isButtonDisabled}
        className={classes.submitButton}
      >
        Indsend priser
      </Button>
    </Grid>
  );
};

export default CompetitorPriceReporting;

import React, { useCallback, useEffect, useState } from "react";
import { Dispatch, AnyAction, bindActionCreators } from "redux";
import { connect, ConnectedProps } from "react-redux";
import { Grid, makeStyles, Snackbar, Typography } from "@material-ui/core";
import { Alert, ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { Cached } from "@material-ui/icons";

import { ApplicationState } from "../../store/Store";
import {
  getUnoxStations,
  getListPrices,
  expandMyStation,
} from "../../store/station/actionCreators";
import { clearAlert } from "../../store/system/actionCreators";
import { UnoxStation } from "../../Models/Station";
import MyStationsList from "./components/MyStationsList";
import { UnoxColors } from "../../constants/theme";
import TopBar from "../../components/TopBar";
import { PageType } from "../../Models/PageType";
import LoadingOverlay from "../../components/LoadingOverlay";

const useStyles = makeStyles({
  listButton: {
    backgroundColor: "white",
    border: "1px solid #9d9d9d",
    marginLeft: "0px!important",
    flex: 1,
    fontWeight: "bold",
    color: UnoxColors.Black,
    "&.Mui-selected": {
      backgroundColor: UnoxColors.Orange,
    },
    "&.Mui-selected:hover": {
      backgroundColor: UnoxColors.Orange,
    },
  },
  listPriceBox: {
    flexDirection: "row",
    display: "flex",
    padding: "0 5px",
    borderRadius: 5,
    background: UnoxColors.White,
    alignItems: "baseline",
    marginLeft: 10,
    width: "auto",
  },
});

const MyStationsPage: React.FC<MyStationsConnectedProps> = (
  props: MyStationsConnectedProps
) => {
  const classes = useStyles();

  const [activeListType, setActiveListType] = useState<
    "all" | "Bonus" | "Unox"
  >("all");

  const getUnoxStations = props.getUnoxStations;
  const getListPrices = props.getListPrices;
  useEffect(() => {
    getUnoxStations();
    getListPrices();
  }, [getUnoxStations, getListPrices]);

  const [activeList, setActiveList] = useState<UnoxStation[]>(props.myStations);

  const handleListTypeChange = useCallback(
    (_: any, newListType: "all" | "Bonus" | "Unox") => {
      setActiveListType(newListType);
      switch (newListType) {
        case "all":
          setActiveList(props.myStations);
          break;
        case "Bonus":
          setActiveList(props.myStations.filter((s) => s.isBonus));
          break;
        case "Unox":
          setActiveList(props.myStations.filter((s) => !s.isBonus));
          break;
      }
    },
    [setActiveListType, props.myStations]
  );

  useEffect(() => {
    handleListTypeChange(null, activeListType);
  }, [props.myStations, activeListType, handleListTypeChange]);

  return (
    <>
      <Grid container>
        <TopBar currentPage={PageType.MyStations}></TopBar>
        <Grid
          item
          container
          justify="space-between"
          style={{ marginBottom: 10 }}
        >
          <Typography variant="h5">Mine stationer</Typography>
          <Cached
            onClick={() => {
              props.getUnoxStations();
              props.getListPrices();
            }}
          />
        </Grid>
        <Grid item container>
          <Typography variant="body2">Filtrer stationer:</Typography>
          <ToggleButtonGroup
            value={activeListType}
            exclusive
            onChange={handleListTypeChange}
            size="small"
            style={{ width: "100%", marginTop: 5 }}
          >
            <ToggleButton className={classes.listButton} value="all">
              Alle
            </ToggleButton>
            <ToggleButton className={classes.listButton} value="Unox">
              Uno-X
            </ToggleButton>
            <ToggleButton className={classes.listButton} value="Bonus">
              Bonus
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
        <Grid
          item
          container
          direction="row"
          justify="flex-end"
          style={{ marginTop: 10 }}
        >
          <Grid item container direction="row" className={classes.listPriceBox}>
            <Typography variant="body2" style={{ paddingRight: 5 }}>
              Blyfri{" "}
            </Typography>
            <Typography>{props.listPriceUnleaded?.toFixed(2)}</Typography>
          </Grid>
          <Grid item container direction="row" className={classes.listPriceBox}>
            <Typography variant="body2" style={{ paddingRight: 5 }}>
              Diesel{" "}
            </Typography>
            <Typography>{props.listPriceDiesel?.toFixed(2)}</Typography>
          </Grid>
        </Grid>
        <Grid item style={{ marginTop: 10, width: "100%" }}>
          <MyStationsList
            myStations={activeList}
            listPriceDiesel={props.listPriceDiesel}
            listPriceUnleaded={props.listPriceUnleaded}
            expandedStationId={props.expandedStationId}
            expandStation={props.expandMyStation}
          ></MyStationsList>
        </Grid>
      </Grid>
      <Snackbar
        autoHideDuration={parseInt(process.env.REACT_APP_ALERT_TIMEOUT || "0")}
        open={!!props.alert}
        onClose={() => props.clearAlert()}
      >
        <Alert severity={props.alert?.type}>{props.alert?.text}</Alert>
      </Snackbar>
      <LoadingOverlay loading={props.loading} />
    </>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  myStations: state.station.myStations,
  listPriceDiesel: state.station.listPriceDiesel,
  listPriceUnleaded: state.station.listPriceUnleaded,
  expandedStationId: state.station.expandedMyStationId,
  alert: state.system.alert,
  loading: state.system.loading,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      getUnoxStations,
      getListPrices,
      clearAlert,
      expandMyStation,
    },
    dispatch
  );

const connector = connect(mapStateToProps, mapDispatchToProps);

type MyStationsConnectedProps = ConnectedProps<typeof connector>;

export default connector(MyStationsPage);

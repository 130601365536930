import React from "react";
import { Provider } from "react-redux";
import {
  BrowserRouter as Router,
  Route,
} from "react-router-dom";

import MyStationsPage from "./pages/MyStations/MyStationsPage";
import LoginPage from "./pages/Login/LoginPage";
import store from "./store/Store";
import { theme, UnoxColors } from "./constants/theme";
import { Grid, ThemeProvider } from "@material-ui/core";
import ForgotPasswordPage from "./pages/ForgotPassword/ForgotPasswordPage";
import PriceReportingPage from "./pages/PriceReporting/PriceReportingPage";
import HomePage from "./pages/Home/HomePage";
import { PagePath } from "./Models/PageType";
import ProtectedRoute from "./components/ProtectedRoute";
import AppDataLoader from "./components/AppDataLoader";
import UserProfilePage from "./pages/UserProfile/UserProfilePage";
import FerieafloeserPage from "./pages/Ferieafloeser/FerieafloeserPage";
import PrisspotterPage from './pages/Prisspotter/PrisspotterPage';
import ChangeProfilePicturePage from './pages/ChangeProfilePicture/ChangeProfilePicturePage';
import ContactsPage from './pages/Contacts/ContactsPage';

function AppRouter() {
  return (
    <Provider store={store}>
      <AppDataLoader />
      <ThemeProvider theme={theme}>
        <Grid
          container
          style={{
            backgroundColor: UnoxColors.LightGreyBackground,
            position: "absolute",
            width: "100%",
            height: "100%",
            paddingLeft: 20,
            paddingRight: 20,
            alignItems: "flex-start",
            overflow: "auto",
          }}
        >
          <Router>
            <Route path={PagePath.Login} exact component={LoginPage} />
            <Route
              path={PagePath.ForgotPassword}
              exact
              component={ForgotPasswordPage}
            />
            <ProtectedRoute path={PagePath.Home} exact component={HomePage} />

            <ProtectedRoute
              path={PagePath.MyStations}
              component={MyStationsPage}
            />
            <ProtectedRoute
              path={PagePath.PriceReporting}
              component={PriceReportingPage}
            />
            <ProtectedRoute
              path={PagePath.UserProfile}
              component={UserProfilePage}
            />
            <ProtectedRoute
              path={PagePath.Ferieafloesere}
              component={FerieafloeserPage}
            />
             <ProtectedRoute
              path={PagePath.Prisspottere}
              component={PrisspotterPage}
            />
            <ProtectedRoute
              path={PagePath.ChangeProfilePicture}
              component={ChangeProfilePicturePage}
            />
            <ProtectedRoute
              path={PagePath.Contacts}
              component={ContactsPage}
            />
          </Router>
        </Grid>
      </ThemeProvider>
    </Provider>
  );
}

export default AppRouter;

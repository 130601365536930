import { User } from "../../Models/User";
import { CHANGE_AVATAR_COMPLETE } from "./actions";
import {
  LOGIN_COMPLETE,
  LOGOFF_COMPLETE,
  UserActionTypes,
  CHANGE_CONTACT_INFO_COMPLETE,
} from "./actions";

export interface UserState {
  currentUser: User | null;
  profilePictureUrl: string | null;
}

export const userInitialState: UserState = {
  currentUser: null,
  profilePictureUrl: null,
};

const userReducer = (
  state = userInitialState,
  action: UserActionTypes
): UserState => {
  switch (action.type) {
    case LOGIN_COMPLETE: {
      return {
        ...state,
        currentUser: action.payload,
        profilePictureUrl: `${process.env.REACT_APP_SERVICE}api/file/GetProfileImage?actorId=${action.payload.actorNumber}`,
      };
    }
    case LOGOFF_COMPLETE: {
      return {
        ...state,
        currentUser: null,
        profilePictureUrl: null,
      };
    }
    case CHANGE_CONTACT_INFO_COMPLETE: {
      return {
        ...state,
        currentUser: {
          ...state.currentUser!,
          phone: action.payload.phone,
          email: action.payload.email,
        },
      };
    }
    case CHANGE_AVATAR_COMPLETE: {
      return {
        ...state,
        profilePictureUrl: `${
          process.env.REACT_APP_SERVICE
        }api/file/GetProfileImage?actorId=${
          state.currentUser?.actorNumber
        }&anticache=${new Date().getTime()}`,
      };
    }
    default:
      return state;
  }
};

export default userReducer;

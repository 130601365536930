import React from "react";
import { Button, Grid, TextField, Typography } from "@material-ui/core";

import { User } from "../../../Models/User";
import { useForm } from "react-hook-form";
import { UnoxColors } from "../../../constants/theme";
import { emailRegexp } from "../../../services/Validation.patterns";

type UserInfoProps = {
  user: User;
  updateUser: (phone: string, email: string) => void;
};

type UserFormData = {
  Email: string;
  Phone: string;
};

const UserInfo: React.FC<UserInfoProps> = (props) => {
  const formMethods = useForm<UserFormData>({ defaultValues: {} });

  const { register, handleSubmit, errors } = formMethods;

  const submit = async (data: UserFormData) => {
    props.updateUser(data.Phone, data.Email);
  };

  return (
    <>
      <Grid item container style={{ marginBottom: 10 }}>
        <Typography
          variant="body2"
          style={{ fontWeight: "bold", marginRight: 10 }}
        >
          Navn:
        </Typography>
        <Typography variant="body2">{props.user?.name}</Typography>
      </Grid>
      <Grid item container style={{ marginBottom: 10 }}>
        <Typography
          variant="body2"
          style={{ fontWeight: "bold", marginRight: 10 }}
        >
          Brugernavn:
        </Typography>
        <Typography variant="body2">{props.user?.userName}</Typography>
      </Grid>

      <form
        onSubmit={handleSubmit(submit)}
        style={{ width: "100%", maxWidth: 400 }}
      >
        <Grid item container style={{ marginBottom: 10, alignItems: "center" }}>
          <Typography variant="body2" style={{ fontWeight: "bold", flex: 1 }}>
            Email:
          </Typography>

          <TextField
            name="Email"
            size="small"
            id="Email"
            color="secondary"
            type="email"
            error={!!errors.Email}
            defaultValue={props.user.email}
            inputRef={register({
              required: true,
              pattern: emailRegexp,
            })}
            style={{ flex: 1.8 }}
          />
        </Grid>

        <Grid item container style={{ marginBottom: 20, alignItems: "center" }}>
          <Typography variant="body2" style={{ fontWeight: "bold", flex: 1 }}>
            Telefonnummer:
          </Typography>
          <TextField
            name="Phone"
            id="Phone"
            size="small"
            color="secondary"
            type="tel"
            error={!!errors.Phone}
            defaultValue={props.user.phone}
            inputRef={register({
              required: true,
            })}
            style={{ flex: 1.8 }}
          />
        </Grid>

        <Grid item container justify="center">
          <Button
            style={{
              backgroundColor: UnoxColors.Green,
              color: UnoxColors.White,
            }}
            variant="contained"
            type="submit"
          >
            Gem ændringer
          </Button>
        </Grid>
      </form>
    </>
  );
};

export default UserInfo;

import React, { useEffect, useRef, useState } from "react";
import { Dispatch, AnyAction, bindActionCreators } from "redux";
import { connect, ConnectedProps } from "react-redux";
import {
  Button,
  Collapse,
  Grid,
  Snackbar,
  Typography,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import { ApplicationState } from "../../store/Store";
import { clearAlert } from "../../store/system/actionCreators";
import {
  getFerieafloesers,
  createFerieafloese,
  editFefieafloeser,
  removeFerieafloeser,
  changePermissions,
  removePermissions,
} from "../../store/ferieafloeser/actionCreators";
import { UnoxColors } from "../../constants/theme";
import TopBar from "../../components/TopBar";
import { PageType } from "../../Models/PageType";
import LoadingOverlay from "../../components/LoadingOverlay";
import CreateFerieafloeser from "./components/CreateFerieafloeser";
import FerieafloeserList from "./components/FerieafloeserList";
import FerieafloeserPermissionList from "./components/FerieafloeserPermissionList";

const FerieafloeserPage: React.FC<FerieafloeserConnectedProps> = (props) => {
  const getFerieafloesers = props.getFerieafloesers;
  useEffect(() => {
    getFerieafloesers();
  }, [getFerieafloesers]);

  const [expandedAccordionKey, setExpandedAccordionKey] = useState<
    number | null
  >(null);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const handleFerieafloeserCreate = (
    name: string,
    userName: string,
    password: string,
    phone: string,
    email: string
  ) => {
    setShowCreateForm(false);
    props.createFerieafloese(
      { name, userName, phone, email, permissions: null, actorNumber: -1 },
      password
    );
  };

  const createFormRef = useRef<HTMLDivElement>(null);

  return (
    <>
      <Grid container direction="column">
        <TopBar currentPage={PageType.Ferieafloesere}></TopBar>
        <Typography variant="h5">Ferieafløsere</Typography>
        <Button
          variant="outlined"
          style={{
            backgroundColor: UnoxColors.Orange,
            color: UnoxColors.Black,
            marginTop: 10,
          }}
          onClick={() => setShowCreateForm(true)}
        >
          Opret ny ferieafløser
        </Button>
        <Collapse
          in={showCreateForm}
          onEntered={() => {
            if (createFormRef && createFormRef.current) {
              createFormRef.current.scrollIntoView({
                behavior: "smooth",
                block: "start",
              });
            }
          }}
        >
          <Grid ref={createFormRef}>
            <CreateFerieafloeser
              cancel={() => setShowCreateForm(false)}
              createFerieafloeser={handleFerieafloeserCreate}
            ></CreateFerieafloeser>
          </Grid>
        </Collapse>
        <Typography
          variant="body2"
          style={{ marginTop: 10, marginBottom: 10, fontWeight: "bold" }}
        >
          Aftaler
        </Typography>
        <FerieafloeserPermissionList
          ferieafloesers={props.ferieafloesers.filter(
            (actor) => actor.permissions && actor.permissions.length > 0
          )}
          removePermissions={props.removePermissions}
          editFefieafloeser={props.editFefieafloeser}
          expandedActor={expandedAccordionKey}
          setExpandedActor={(actor) => setExpandedAccordionKey(actor)}
        ></FerieafloeserPermissionList>

        <Typography
          variant="body2"
          style={{ marginTop: 10, marginBottom: 10, fontWeight: "bold" }}
        >
          Mine afløsere
        </Typography>
        <FerieafloeserList
          ferieafloesers={props.ferieafloesers}
          permissionStations={props.permissionStations}
          changePermissions={props.changePermissions}
          editFefieafloeser={props.editFefieafloeser}
          removeFerieafloeser={props.removeFerieafloeser}
          expandedActor={expandedAccordionKey}
          setExpandedActor={(actor) => setExpandedAccordionKey(actor)}
        ></FerieafloeserList>
        {/* remain some space in the bottom to allow scrolling content to the top */}
        <Grid style={{ height: 200, width: 1 }}></Grid>
      </Grid>
      <Snackbar
        autoHideDuration={parseInt(process.env.REACT_APP_ALERT_TIMEOUT || "0")}
        open={!!props.alert}
        onClose={() => props.clearAlert()}
      >
        <Alert severity={props.alert?.type}>{props.alert?.text}</Alert>
      </Snackbar>
      <LoadingOverlay loading={props.loading} />
    </>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  alert: state.system.alert,
  loading: state.system.loading,
  ferieafloesers: state.ferieafloeser.ferieafloesers,
  permissionStations: state.ferieafloeser.permissionStations,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      getFerieafloesers,
      createFerieafloese,
      editFefieafloeser,
      removeFerieafloeser,
      changePermissions,
      removePermissions,
      clearAlert,
    },
    dispatch
  );

const connector = connect(mapStateToProps, mapDispatchToProps);

type FerieafloeserConnectedProps = ConnectedProps<typeof connector>;

export default connector(FerieafloeserPage);

import UiMessage from "../../Models/UiMessage";
import {
  CHANGE_AVATAR,
  CHANGE_AVATAR_COMPLETE,
  LOGIN_COMPLETE,
  LOGOFF_COMPLETE,
} from "../user/actions";
import {
  ALERT,
  SystemActionTypes,
  CLEAR_ALERT,
  SHOW_SPINNER,
  SET_LOCAL_SETTINGS,
} from "./actions";
import { ContactActionTypes, GET_CONTACTS_COMPLETE } from "../contacts/actions";
import { LocalSettings } from "../../Models/LocalSettings";
import {
  CHANGE_CONTACT_INFO,
  UserActionTypes,
  CHANGE_CONTACT_INFO_COMPLETE,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_COMPLETE,
} from "../user/actions";
import {
  StationActionTypes,
  GET_COMPETITOR_STATIONS,
  GET_COMPETITOR_STATIONS_COMPLETE,
  GET_MY_STATIONS,
  GET_MY_STATIONS_COMPLETE,
} from "../station/actions";
import {
  GET_FERIEAFLOESERS_COMPLETE,
  CHANGE_FERIEAFLOESER_PERMISSIONS_COMPLETE,
  REMOVE_FERIEAFLOESER_PERMISSIONS_COMPLETE,
  FerieafloeserActionTypes,
  EDIT_FERIEAFLOESER_COMPLETE,
  REMOVE_FERIEAFLOESER_COMPLETE,
} from "../ferieafloeser/actions";

import {
  GET_PRISSPOTTERS_COMPLETE,
  EDIT_PRISSPOTTERS_COMPLETE,
  REMOVE_PRISSPOTTERS_COMPLETE,
  REMOVE_PRISSPOTTER_PERMISSIONS_COMPLETE,
  PrisspotterActionTypes,
} from "../prisspotter/actions";

export interface SystemState {
  loading: boolean;
  alert: UiMessage | null;
  localSettings: LocalSettings;
}

export const systemInitialState: SystemState = {
  loading: false,
  alert: null,
  localSettings: {
    SortUnoxStationsByLocation: true,
    SortCompetitorStationsByLocation: true,
    LocationGettingTimeout: parseInt(process.env.REACT_APP_GPS_TIMEOUT || "0"),
  },
};

const systemReducer = (
  state = systemInitialState,
  action:
    | SystemActionTypes
    | UserActionTypes
    | StationActionTypes
    | FerieafloeserActionTypes
    | PrisspotterActionTypes
    | ContactActionTypes
): SystemState => {
  switch (action.type) {
    case ALERT:
      return { ...state, alert: action.payload, loading: false };
    case CLEAR_ALERT:
      return { ...state, alert: null };
    case SHOW_SPINNER:
    case CHANGE_CONTACT_INFO:
    case GET_MY_STATIONS:
    case GET_COMPETITOR_STATIONS:
    case CHANGE_PASSWORD:
    case CHANGE_AVATAR: {
      return {
        ...state,
        loading: true,
      };
    }
    case LOGIN_COMPLETE:
    case CHANGE_CONTACT_INFO_COMPLETE:
    case CHANGE_PASSWORD_COMPLETE:
    case GET_MY_STATIONS_COMPLETE:
    case GET_COMPETITOR_STATIONS_COMPLETE:
    case GET_FERIEAFLOESERS_COMPLETE:
    case EDIT_FERIEAFLOESER_COMPLETE:
    case REMOVE_FERIEAFLOESER_COMPLETE:
    case CHANGE_FERIEAFLOESER_PERMISSIONS_COMPLETE:
    case REMOVE_FERIEAFLOESER_PERMISSIONS_COMPLETE:
    case GET_PRISSPOTTERS_COMPLETE:
    case EDIT_PRISSPOTTERS_COMPLETE:
    case REMOVE_PRISSPOTTERS_COMPLETE:
    case REMOVE_PRISSPOTTER_PERMISSIONS_COMPLETE:
    case LOGOFF_COMPLETE:
    case CHANGE_AVATAR_COMPLETE:
    case GET_CONTACTS_COMPLETE: {
      return {
        ...state,
        loading: false,
      };
    }
    case SET_LOCAL_SETTINGS: {
      return {
        ...state,
        localSettings: action.payload,
      };
    }
    default:
      return state;
  }
};

export default systemReducer;

import { Ferieafloeser } from "../../Models/Ferieafloeser";
import { StationPermission } from "../../Models/StationPermission";
import { LOGOFF_COMPLETE, UserActionTypes } from '../user/actions';
import {
  EDIT_FERIEAFLOESER_COMPLETE,
  FerieafloeserActionTypes,
  GET_FERIEAFLOESERS_COMPLETE,
  REMOVE_FERIEAFLOESER_COMPLETE,
  REMOVE_FERIEAFLOESER_PERMISSIONS_COMPLETE,
} from "./actions";

export interface FerieafloeserState {
  ferieafloesers: Ferieafloeser[];
  permissionStations: StationPermission[];
}

export const ferieafloeserInitialState: FerieafloeserState = {
  ferieafloesers: [],
  permissionStations: [],
};

const ferieafloeserReducer = (
  state = ferieafloeserInitialState,
  action: FerieafloeserActionTypes | UserActionTypes
): FerieafloeserState => {
  switch (action.type) {
    case GET_FERIEAFLOESERS_COMPLETE: {
      return {
        ...state,
        ferieafloesers: action.payload.ferieafloesers,
        permissionStations: action.payload.permissionStations,
      };
    }
    case EDIT_FERIEAFLOESER_COMPLETE: {
      return {
        ...state,
        ferieafloesers: state.ferieafloesers.map((actor) =>
          actor.actorNumber === action.payload.actorNumber
            ? {
                ...actor,
                email: action.payload.email,
                phone: action.payload.phone,
              }
            : actor
        ),
      };
    }
    case REMOVE_FERIEAFLOESER_COMPLETE: {
      return {
        ...state,
        ferieafloesers: state.ferieafloesers.filter(
          (actor) => actor.actorNumber !== action.payload
        ),
      };
    }
    case REMOVE_FERIEAFLOESER_PERMISSIONS_COMPLETE: {
      return {
        ...state,
        ferieafloesers: state.ferieafloesers.map((actor) =>
          actor.actorNumber === action.payload
            ? { ...actor, permissions: null }
            : actor
        ),
      };
    }
    case LOGOFF_COMPLETE: {
      return  ferieafloeserInitialState;
    }
    default:
      return state;
  }
};

export default ferieafloeserReducer;

import React from "react";
import { Dispatch, AnyAction, bindActionCreators } from "redux";
import { connect, ConnectedProps } from "react-redux";
import {
  Button,
  Grid,
  Snackbar,
  Typography,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import { ApplicationState } from "../../store/Store";
import { clearAlert } from "../../store/system/actionCreators";
import TopBar from "../../components/TopBar";
import { PageType } from "../../Models/PageType";
import LoadingOverlay from "../../components/LoadingOverlay";
import FileInput from "../../components/FileInput";
import { changeAvatar } from "../../store/user/actionCreators";
import { UnoxColors } from "../../constants/theme";

const ChangeProfilePicturePage: React.FC<ChangeProfilePictureConnectedProps> = (
  props
) => {
  const updatePictureHandler = (picture: File) => {
    props.changeAvatar(picture);
  };

  return (
    <>
      <Grid container>
        <TopBar currentPage={PageType.UserProfile}></TopBar>
        <Typography variant="h5" style={{ marginBottom: 20 }}>
          Skift profilbillede
        </Typography>
        {props.user && (
          <Grid container direction="column">
            <Typography variant="body2">
              Skift dit profilbillede ved at klikke på knappen "Gennemse' og
              vælg et billede i jpg format.
            </Typography>
            <Button
              variant="outlined"
              style={{
                backgroundColor: UnoxColors.Orange,
                color: UnoxColors.Black,
                marginTop: 10,
                width: "100%",
                maxWidth: 400
              }}
            >
              <FileInput getPicture={updatePictureHandler}>
                <span>Upload nyt profilbillede</span>
              </FileInput>
            </Button>
          </Grid>
        )}
      </Grid>
      <Snackbar
        autoHideDuration={parseInt(process.env.REACT_APP_ALERT_TIMEOUT || "0")}
        open={!!props.alert}
        onClose={() => props.clearAlert()}
      >
        <Alert severity={props.alert?.type}>{props.alert?.text}</Alert>
      </Snackbar>
      <LoadingOverlay loading={props.loading} />
    </>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  user: state.user.currentUser,
  alert: state.system.alert,
  loading: state.system.loading,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      changeAvatar,
      clearAlert,
    },
    dispatch
  );

const connector = connect(mapStateToProps, mapDispatchToProps);

type ChangeProfilePictureConnectedProps = ConnectedProps<typeof connector>;

export default connector(ChangeProfilePicturePage);

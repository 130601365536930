import React from "react";
import { Button, Grid, TextField, Typography } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { useForm } from "react-hook-form";

import { UnoxColors } from "../../../constants/theme";
import {
  emailRegexp,
  oneLowerCaseRegexp,
  passwordRegexp,
} from "../../../services/Validation.patterns";

type CreateFerieafloeserProps = {
  createFerieafloeser: (
    name: string,
    userName: string,
    password: string,
    phone: string,
    email: string
  ) => void;
  cancel: () => void;
};

type CreateFerieafloeserFormData = {
  Name: string;
  UserName: string;
  Password: string;
  PasswordConfirm: string;
  Email: string;
  Phone: string;
};

const CreateFerieafloeser: React.FC<CreateFerieafloeserProps> = (props) => {
  const formMethods = useForm<CreateFerieafloeserFormData>({
    defaultValues: {},
  });

  const { register, handleSubmit, errors, getValues, reset } = formMethods;

  const submit = async (data: CreateFerieafloeserFormData) => {
    props.createFerieafloeser(
      data.Name,
      data.UserName,
      data.Password,
      data.Phone,
      data.Email
    );
    reset();
  };

  const PasswordRequirementText: React.FC<{}> = ({ children }) => {
    return (
      <Typography
        variant="body2"
        style={
          errors.Password ? { color: UnoxColors.Red, marginRight: 15 } : {}
        }
      >
        {children}
      </Typography>
    );
  };

  const validateConfirmPassword = (value: any) => {
    const newPassword = getValues().Password;
    if (value === newPassword) {
      return true;
    } else {
      return "Bekræftet kodeord skal være den samme som kodeord.";
    }
  };

  return (
    <>
      <Typography variant="h5" style={{ marginBottom: 10, marginTop: 10 }}>
        Opret ny ferieafløser
      </Typography>

      <form
        onSubmit={handleSubmit(submit)}
        style={{ width: "100%", maxWidth: 400 }}
      >
        <Grid item container style={{ marginBottom: 10, alignItems: "center" }}>
          <Typography variant="body2" style={{ fontWeight: "bold", flex: 1 }}>
            Navn:
          </Typography>

          <TextField
            name="Name"
            size="small"
            color="secondary"
            error={!!errors.Name}
            inputRef={register({
              required: true,
              pattern: {
                value: oneLowerCaseRegexp,
                message: "Mindst et lille bogstav",
              },
            })}
            style={{ flex: 1 }}
          />
        </Grid>
        {errors.Name && errors.Name.message && (
          <Alert severity="error">{errors.Name.message}</Alert>
        )}

        <Grid item container style={{ marginBottom: 10, alignItems: "center" }}>
          <Typography variant="body2" style={{ fontWeight: "bold", flex: 1 }}>
            Brugernavn:
          </Typography>

          <TextField
            name="UserName"
            size="small"
            color="secondary"
            error={!!errors.UserName}
            inputRef={register({
              required: true,
              pattern: {
                value: oneLowerCaseRegexp,
                message: "Mindst et lille bogstav",
              },
            })}
            style={{ flex: 1 }}
          />
        </Grid>
        {errors.UserName && errors.UserName.message && (
          <Alert severity="error">{errors.UserName.message}</Alert>
        )}

        <Grid item container style={{ marginBottom: 10, alignItems: "center" }}>
          <Typography variant="body2" style={{ fontWeight: "bold", flex: 1 }}>
            Kodeord:
          </Typography>

          <TextField
            name="Password"
            size="small"
            color="secondary"
            type="password"
            error={!!errors.Password}
            inputRef={register({
              required: true,
              pattern: passwordRegexp,
            })}
            style={{ flex: 1 }}
          />
        </Grid>

        <Grid item container direction="row">
          <PasswordRequirementText>
            Mindst et lille bogstav
          </PasswordRequirementText>
          <PasswordRequirementText>
            Mindst et stort bogstav
          </PasswordRequirementText>
          <PasswordRequirementText>Mindst et tal</PasswordRequirementText>
          <PasswordRequirementText>Mindst 8 tegn</PasswordRequirementText>
          <PasswordRequirementText>
            Kun tal og bogstaver
          </PasswordRequirementText>
        </Grid>

        <Grid item container style={{ marginBottom: 10, alignItems: "center" }}>
          <Typography variant="body2" style={{ fontWeight: "bold", flex: 1 }}>
            Bekræft kodeord:
          </Typography>

          <TextField
            name="PasswordConfirm"
            size="small"
            color="secondary"
            type="password"
            error={!!errors.PasswordConfirm}
            inputRef={register({
              required: true,
              pattern: passwordRegexp,
              validate: validateConfirmPassword,
            })}
            style={{ flex: 1 }}
          />
        </Grid>
        {errors.PasswordConfirm && errors.PasswordConfirm.message && (
          <Alert severity="error">{errors.PasswordConfirm.message}</Alert>
        )}

        <Grid item container style={{ marginBottom: 10, alignItems: "center" }}>
          <Typography variant="body2" style={{ fontWeight: "bold", flex: 1 }}>
            Email:
          </Typography>

          <TextField
            name="Email"
            size="small"
            color="secondary"
            type="email"
            error={!!errors.Email}
            inputRef={register({
              required: true,
              pattern: { value: emailRegexp, message: "Ugyldig email adresse" },
            })}
            style={{ flex: 1 }}
          />
        </Grid>

        <Grid item container style={{ marginBottom: 20, alignItems: "center" }}>
          <Typography variant="body2" style={{ fontWeight: "bold", flex: 1 }}>
            Telefonnummer:
          </Typography>
          <TextField
            name="Phone"
            id="Phone"
            size="small"
            color="secondary"
            type="tel"
            error={!!errors.Phone}
            inputRef={register({
              required: true,
            })}
            style={{ flex: 1 }}
          />
        </Grid>

        <Grid item container direction="row" justify="space-between">
          <Button
            style={{
              backgroundColor: UnoxColors.Red,
              color: UnoxColors.White,
              maxWidth: 160,
              marginRight: 20,
              flex: 1,
            }}
            variant="contained"
            onClick={() => {
              reset();
              props.cancel();
            }}
          >
            Annuller
          </Button>
          <Button
            style={{
              backgroundColor: UnoxColors.Green,
              color: UnoxColors.White,
              maxWidth: 160,
              flex: 1,
            }}
            variant="contained"
            type="submit"
          >
            Opret
          </Button>
        </Grid>
      </form>
    </>
  );
};

export default CreateFerieafloeser;

import React from "react";
import { makeStyles } from "@material-ui/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles({
  loadingSpinner: {
    position: "fixed",
    zIndex: 1300,
    right: 0,
    bottom: 0,
    top: 0,
    left: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(0,0,0,0.5)"
  }
});

type LoadingOverlayProps = {
    loading: boolean;
    value?: number;
}

const LoadingOverlay = ({ loading, value } :LoadingOverlayProps) => {
  const classes = useStyles();
  return loading ? (
    <div className={classes.loadingSpinner}>
       {value ? (
        <CircularProgress variant="static" value={value} />
      ) : (
        <CircularProgress />
      )}
    </div>
  ) : null;
};

export default LoadingOverlay;

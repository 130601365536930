import React, { useRef, ReactNode } from "react";

type PictureInputProps = {
  getPicture: (imageData: File) => void;
  children: ReactNode;
};

type WrapperProps = {
  children: ReactNode;
  handler: () => void;
}

const Wrapper = (props: WrapperProps) => {
  return (
    <span onClick={props.handler} style={{cursor: "pointer"}}>
      {props.children}
    </span>
  )
}

const FileInput = (props: PictureInputProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const showFileUpload = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const capture = (imageData: File) => {
    if (imageData) {
      props.getPicture(imageData);
    }
  };

  const onFilesAdded = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (
      event &&
      event.currentTarget &&
      event.currentTarget.files &&
      event.currentTarget.files.length
    ) {
      const file = event.currentTarget.files[0];
      capture(file);
    }
  };

  return (
    <>
      <Wrapper handler={showFileUpload}>
        {props.children}
      </Wrapper>

      <input
        ref={inputRef}
        style={{ display: "none" }}
        type="file"
        accept="image/jpeg"
        onChange={onFilesAdded}
      />
    </>
  );
};

export default FileInput;

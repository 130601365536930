import ApiResponse from "../../Models/ApiResponse";
import AuthTokenResponse from "../../Models/DTO/AuthTokenResponse";
import IAuthAPIService from "./IAuthApiService";

export class AuthAPIService implements IAuthAPIService {
  getToken(
    userName: string,
    password: string
  ): Promise<ApiResponse<AuthTokenResponse>> {
    const details: Record<string, any> = {
      username: userName,
      password: password,
      grant_type: "password",
    };

    return this.sendTokenRequest(details);
  }

  refreshToken(refreshToken: string): Promise<ApiResponse<AuthTokenResponse>> {
    const details: Record<string, string> = {
      refresh_token: refreshToken,
      grant_type: "refresh_token",
    };

    return this.sendTokenRequest(details);
  }

  private async sendTokenRequest(
    requestData: Record<string, string>
  ): Promise<ApiResponse<AuthTokenResponse>> {
    const details: Record<string, string> = {
      ...requestData,
      client_id: "mobileapp",
      client_secret: "secret",
      scope: "openid offline_access pricereporting",
    };

    const formBody = [];
    for (const property in details) {
      const encodedKey = encodeURIComponent(property);
      const encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    const formBodyString = formBody.join("&");

    const url = process.env.REACT_APP_AUTH_SERVICE + "connect/token";
    const headers = { "Content-Type": "application/x-www-form-urlencoded" };

    const response = await fetch(url, {
      method: "POST",
      headers: headers,
      body: formBodyString,
    });

    if (response.status >= 400) {
      return {
        isError: true,
      };
    }

    const data = await response.json();
    return {
      data: {
        AccessToken: data.access_token,
        RefreshToken: data.refresh_token,
      },
      isError: false,
    };
  }
}

import { LocalSettings } from "../Models/LocalSettings";
import ILocalSettingsService from "./ILocalSettingsService";

export class LocalSettingsService implements ILocalSettingsService {
  restoreLocalSettings(): LocalSettings {
    let settings: LocalSettings = {
      SortUnoxStationsByLocation: true,
      SortCompetitorStationsByLocation: true,
      LocationGettingTimeout: parseInt(process.env.REACT_APP_GPS_TIMEOUT || "0"),
    };

    const localSettingsString = localStorage.getItem("localSettings");
    if (localSettingsString) {
      const localSettings = JSON.parse(localSettingsString);
      settings = { ...settings, ...localSettings };
    }

    return settings;
  }

  storeLocalSettings(settings: LocalSettings) {
    localStorage.setItem("localSettings", JSON.stringify(settings));
  }
}

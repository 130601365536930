import { unstable_createMuiStrictModeTheme as createMuiTheme, responsiveFontSizes } from "@material-ui/core/styles";

declare module "@material-ui/core/styles/createPalette" {
  interface PaletteOptions {
    green?: PaletteColorOptions;
    red?: PaletteColorOptions;
    black?: PaletteColorOptions;
  }
  interface Palette {
    facebook: PaletteColor;
    green: PaletteColor;
    red: PaletteColor;
    [key: string]: any;
  }
}

export type colorType =
  | "primary"
  | "secondary"
  | "green"
  | "red"
  | "black"
  | "background"
  | null
  | undefined;

export enum UnoxColors {
  White = "#fff",
  Orange = "#f0b600",
  Black = "#4a4a4a",
  LightGreyBackground = "#f2f2f2",
  Green = "#009a52",
  Red = "#ed192e",
}

/**
 * DO NOT IMPORT IT DIRECTLY EXCEPT OF TO THE THEME PROVIDER IN APP.TSX
 */
export const theme = responsiveFontSizes(
  createMuiTheme({
    palette: {
      primary: {
        main: UnoxColors.Orange,
        contrastText: UnoxColors.Black,
      },
      secondary: {
        main: UnoxColors.White,
        contrastText: UnoxColors.White,
      },
      green: {
        main: UnoxColors.Green,
        contrastText: UnoxColors.Black,
      },
      red: {
        main: UnoxColors.Red,
        contrastText: UnoxColors.Black,
      },
      black: {
        main: UnoxColors.Black,
        contrastText: UnoxColors.White,
      },
      background: {
        default: UnoxColors.LightGreyBackground,
        paper: UnoxColors.LightGreyBackground,
      },
    },
    typography: {
      fontFamily: "Arial, Helvetica, sans-serif",
      button: {
        color: UnoxColors.Black,
      },
      h1: {
        color: UnoxColors.Black,
      },
      h2: {
        color: UnoxColors.Black,
      },
      h3: {
        color: UnoxColors.Black,
      },
      h4: {
        color: UnoxColors.Black,
      },
      h5: {
        color: UnoxColors.Black,
      },
      h6: {
        color: UnoxColors.Black,
      },
      body1: {
        color: UnoxColors.Black,
      },
      body2: {
        color: UnoxColors.Black,
      },
      subtitle2: {
        color: UnoxColors.Black,
      },
    },
    overrides: {
      // remove chrome autofill background
      MuiInputBase: {
        input: {
          "&:-webkit-autofill": {
            transitionDelay: "9999s",
            transitionProperty: "background-color, color",
          },
        },
      },
    },

    props: {
      MuiSvgIcon: {
        htmlColor: UnoxColors.Black,
      },
    },
  })
);

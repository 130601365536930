import { User } from "../../Models/User";
import IUserApiService from "./IUserApiService";
import IHttpService from "./IHttpService";
import ApiResponse from "../../Models/ApiResponse";
import FuelServerResponse from "../../Models/DTO/FuelServerResponse";
import { ChangeContactInfoRequest } from "../../Models/DTO/ChangeContactInfoRequest";
import { ChangePasswordRequest } from "../../Models/DTO/ChangePasswordRequest";

export class UserApiService implements IUserApiService {
  constructor(private httpService: IHttpService) {}

  async getUserDetails(): Promise<ApiResponse<User>> {
    const url = `${process.env.REACT_APP_SERVICE}api/user/GetUserDetails`;
    const response = await this.httpService.get<FuelServerResponse<User>>(url);

    if (response.isError || !response.data) {
      return { isError: true, IsNotAuthorized: response.IsNotAuthorized };
    }

    const data = response.data;

    if (data.isOk) {
      return { isError: false, data: data.result };
    } else {
      return {
        isError: true,
        error: "Kan ikke indlæse bruger info. " + (data.errorDescription ?? ""),
      };
    }
  }

  async resetPassword(userName: string, email: string): Promise<void> {
    const url =
      process.env.REACT_APP_AUTH_SERVICE +
      "/Features/ResetPasswordByEmailAndUserName";
    await this.httpService.post(
      url,
      { email: email, userName: userName },
      undefined,
      true
    );
  }

  async changeContactInfo(
    request: ChangeContactInfoRequest
  ): Promise<ApiResponse<void>> {
    const url = `${process.env.REACT_APP_SERVICE}api/user/ChangeContactInfo`;
    const errorText = "Kan ikke opdatere bruger. ";
    const response = await this.httpService.post<
      FuelServerResponse<void>
    >(url, request);

    if (response.isError || !response.data) {
      return {
        isError: true,
        error: errorText,
        IsNotAuthorized: response.IsNotAuthorized,
      };
    }

    const data = response.data;

    if (data.isOk) {
      return { isError: false, data: data.result };
    } else {
      return {
        isError: true,
        error: errorText + (data.errorDescription ?? ""),
      };
    }
  }

  async changePassword(
    request: ChangePasswordRequest
  ): Promise<ApiResponse<void>> {
    const url = `${process.env.REACT_APP_SERVICE}api/user/ChangePassword`;
    const errorText = "Kan ikke ændre adgangskode. ";
    const response = await this.httpService.post<FuelServerResponse<void>>(
      url,
      request
    );

    if (response.isError || !response.data) {
      return {
        isError: true,
        error: errorText,
        IsNotAuthorized: response.IsNotAuthorized,
      };
    }

    const data = response.data;

    if (data.isOk) {
      return { isError: false, data: data.result };
    } else {
      return {
        isError: true,
        error: errorText + (data.errorDescription ?? ""),
      };
    }
  }

  async changeAvatar(
    picture: File
  ): Promise<ApiResponse<void>> {
    const url = `${process.env.REACT_APP_SERVICE}api/file/UploadProfileImage`;
    const errorText = "Kan ikke skift profilbillede. ";

    const formData = new FormData();
    const blob = new Blob([picture], { type: picture.type });
    formData.append("File", blob);

    const headers = { "Content-Type": "" };

    const response = await this.httpService.post<FuelServerResponse<void>>(
      url,
      formData,
      headers,
    );

    if (response.isError || !response.data) {
      return {
        isError: true,
        error: errorText,
        IsNotAuthorized: response.IsNotAuthorized,
      };
    }

    const data = response.data;

    if (data.isOk) {
      return { isError: false, data: data.result };
    } else {
      return {
        isError: true,
        error: errorText + (data.errorDescription ?? ""),
      };
    }
  }
}

import GetPrisspottersResponse from '../../Models/DTO/GetPrisspottersResponse';


export const GET_PRISSPOTTERS_COMPLETE = "GET_PRISSPOTTERS_COMPLETE";
export const EDIT_PRISSPOTTERS_COMPLETE = "EDIT_PRISSPOTTERS_COMPLETE";
export const REMOVE_PRISSPOTTERS_COMPLETE = "REMOVE_PRISSPOTTERS_COMPLETE";
export const ADD_PRISSPOTTERS_PERMISSIONS_COMPLETE =
  "ADD_PRISSPOTTERS_PERMISSIONS_COMPLETE";
export const REMOVE_PRISSPOTTER_PERMISSIONS_COMPLETE =
  "REMOVE_PRISSPOTTER_PERMISSIONS_COMPLETE";

interface GetPrisspottersCompleteAction {
  type: typeof GET_PRISSPOTTERS_COMPLETE;
  payload: GetPrisspottersResponse;
}

interface EditPrisspotterCompleteAction {
  type: typeof EDIT_PRISSPOTTERS_COMPLETE;
  payload: {actorNumber: number;};
}

interface RemovePrisspotterCompleteAction {
  type: typeof REMOVE_PRISSPOTTERS_COMPLETE;
  payload: number;
}

/*
interface AddPrisspotterPermissionsCompleteAction {
  type: typeof ADD_PRISSPOTTERS_PERMISSIONS_COMPLETE;
  payload: { actorNumber: number; permissions: StationPermission };
}*/


interface RemovePrisspotterPermissionsCompleteAction {
  type: typeof REMOVE_PRISSPOTTER_PERMISSIONS_COMPLETE;
  payload: number;
}

export type PrisspotterActionTypes =
  | GetPrisspottersCompleteAction
  | EditPrisspotterCompleteAction
  | RemovePrisspotterCompleteAction
  | RemovePrisspotterPermissionsCompleteAction;

import React, { useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Collapse,
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import { ExpandMore, Edit } from "@material-ui/icons";

import { UnoxColors } from "../../../constants/theme";
import { Ferieafloeser } from "../../../Models/Ferieafloeser";
import EditFerieafloeser from "./EditFerieafloeser";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    accordionHeader: {
      "&.Mui-expanded": {
        backgroundColor: UnoxColors.Orange,
      },
    },
  })
);

type FerieafloeserListProps = {
  ferieafloeser: Ferieafloeser;
  uniqueKey: number;
  permissionsSummary: string;
  expandedKey: number | null;
  setExpandedKey: (key: number | null) => void;
  editFefieafloeser: (
    actorNumber: number,
    password: string,
    email: string,
    phone: string
  ) => void;
};

const FerieafloeserAccordionWrapper: React.FC<FerieafloeserListProps> = (
  props
) => {
  const classes = useStyles();

  const [showActorEditForm, setShowActorEditForm] = useState<boolean>(false);

  return (
    <Accordion
      style={{ width: "100%" }}
      expanded={props.expandedKey === props.uniqueKey}
      onChange={(_: object, expanded: boolean) =>
        props.setExpandedKey(expanded ? props.uniqueKey : null)
      }
      key={props.uniqueKey}
    >
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="panel1a-content"
        className={classes.accordionHeader}
      >
        <Grid container direction="row">
          <span
            style={{
              marginRight: 5,
              color: props.ferieafloeser.permissions?.length
                ? UnoxColors.Green
                : UnoxColors.Red,
            }}
          >
            &#9679;
          </span>
          <img
              src={`${process.env.REACT_APP_SERVICE}api/file/GetProfileImage?actorId=${props.ferieafloeser.actorNumber}`}
              style={{
                width: 35,
                height: 35,
                objectFit: "contain",
                marginRight: 5,
              }}
              alt="profilbillede"
            />
          <Grid container direction="column" style={{ width: "auto" }}>
            <Typography variant="body2" style={{ fontWeight: "bold" }}>
              {props.ferieafloeser.name}
            </Typography>
            <Typography variant="body2" style={{letterSpacing: -1.1}}>{props.permissionsSummary}</Typography>
            <Typography variant="body2" >ID: {props.ferieafloeser.actorNumber}</Typography>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container direction="column">
          <Grid
            container
            direction="row"
            justify="space-between"
            style={{ alignItems: "center" }}
          >
            <Grid item container direction="row" style={{ flex: 1 }}>
              <Typography variant="body2" style={{ fontWeight: "bold" }}>
                Telefon:
              </Typography>
              <a
                href={"tel:" + props.ferieafloeser.phone}
                style={{ color: UnoxColors.Black }}
              >
                <Typography variant="body2">
                  {props.ferieafloeser.phone}
                </Typography>
              </a>
            </Grid>
            <Button
              onClick={() => setShowActorEditForm(true)}
              size="small"
              startIcon={<Edit />}
            >
              Redigér profil
            </Button>
          </Grid>
          <Grid container direction="row">
            <Typography variant="body2" style={{ fontWeight: "bold" }}>
              E-mail:
            </Typography>
            <a
              href={"mailto:" + props.ferieafloeser.email}
              style={{ color: UnoxColors.Black }}
            >
              <Typography variant="body2">
                {props.ferieafloeser.email}
              </Typography>
            </a>
          </Grid>
          <Collapse in={showActorEditForm} onEntered={() => {}}>
            <EditFerieafloeser
              ferieafloeser={props.ferieafloeser}
              cancel={() => setShowActorEditForm(false)}
              editFefieafloeser={(
                actorNumber: number,
                password: string,
                email: string,
                phone: string
              ) => {
                props.editFefieafloeser(actorNumber, password, email, phone);
                setShowActorEditForm(false);
              }}
            ></EditFerieafloeser>
          </Collapse>

          {!showActorEditForm && props.children}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default FerieafloeserAccordionWrapper;

import React from "react";
import { Dispatch, AnyAction, bindActionCreators } from "redux";
import { connect, ConnectedProps } from "react-redux";
import { Divider, Grid, Snackbar, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import { ApplicationState } from "../../store/Store";
import {
  changeContactInfo,
  changePassword,
} from "../../store/user/actionCreators";
import {
  clearAlert,
  setLocalSettings,
} from "../../store/system/actionCreators";
import TopBar from "../../components/TopBar";
import { PageType } from "../../Models/PageType";
import UserInfo from "./components/UserInfo";
import LoadingOverlay from "../../components/LoadingOverlay";
import ChangePassword from "./components/ChangePassword";
import LocalSettingsComponent from "./components/LocalSettingsComponent";

const UserProfilePage: React.FC<UserProfileConnectedProps> = (props) => {
  return (
    <>
      <Grid container>
        <TopBar currentPage={PageType.UserProfile}></TopBar>
        <Typography variant="h5" style={{ marginBottom: 20 }}>
          Min profil
        </Typography>
        {props.user && (
          <>
            <UserInfo
              user={props.user}
              updateUser={props.changeContactInfo}
            ></UserInfo>
            <Divider
              style={{ width: "100%", marginTop: 20, marginBottom: 10 }}
            />
            <ChangePassword
              updatePassword={props.changePassword}
            ></ChangePassword>
            <Divider
              style={{ width: "100%", marginTop: 20, marginBottom: 10 }}
            />
            <LocalSettingsComponent
              settings={props.localSettings}
              updateSettings={props.setLocalSettings}
            />
          </>
        )}
      </Grid>
      <Snackbar
        autoHideDuration={parseInt(process.env.REACT_APP_ALERT_TIMEOUT || "0")}
        open={!!props.alert}
        onClose={() => props.clearAlert()}
      >
        <Alert severity={props.alert?.type}>{props.alert?.text}</Alert>
      </Snackbar>
      <LoadingOverlay loading={props.loading} />
    </>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  user: state.user.currentUser,
  alert: state.system.alert,
  loading: state.system.loading,
  localSettings: state.system.localSettings,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      changeContactInfo,
      changePassword,
      clearAlert,
      setLocalSettings,
    },
    dispatch
  );

const connector = connect(mapStateToProps, mapDispatchToProps);

type UserProfileConnectedProps = ConnectedProps<typeof connector>;

export default connector(UserProfilePage);

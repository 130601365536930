import React from "react";
import { Grid } from "@material-ui/core";

import { Prisspotter } from "../../../Models/Prisspotter";
import { StationPermission } from "../../../Models/StationPermission";
import PrisspotterActionButtonsComponent from "./PrisspotterActionButtonsComponent";
import PrisspotterAccordionWrapper from "./PrisspotterAccordionWrapper";

type PrisspotterListProps = {
  prisspotters: Prisspotter[];
  permissionStations: StationPermission[];
  expandedActor: number | null;
  setExpandedActor: (actorNumber: number | null) => void;
  editPrisspotter: (actorNumber: number, password: string) => void;
  removePrisspotter: (actorNumber: number) => void;
  addPermissions: (
    actorNumber: number,
    permissions: StationPermission[]
  ) => void;
};

const PrisspotterList: React.FC<PrisspotterListProps> = (props) => {
  const getUserPermissionSummary = (prisspotter: Prisspotter) => {
    if (!prisspotter.permissions || prisspotter.permissions.length === 0) {
      return "Ingen aftaler booket";
    }
    if (prisspotter.permissions.length > 0) {
      return "Aftaler booket";
    }
    return "";
  };

  return (
    <Grid container justify="center">
      {props.prisspotters.map((prisspotter: Prisspotter) => (
        <PrisspotterAccordionWrapper
          prisspotter={prisspotter}
          uniqueKey={prisspotter.actorNumber}
          key={prisspotter.actorNumber}
          expandedKey={props.expandedActor}
          permissionsSummary={getUserPermissionSummary(prisspotter)}
          setExpandedKey={props.setExpandedActor}
          editPrisspotter={(actorNumber: number, password: string) =>
            props.editPrisspotter(actorNumber, password)
          }
        >
          <PrisspotterActionButtonsComponent
            actorNumber={prisspotter.actorNumber}
            availablePermissionStations={props.permissionStations}
            removePrisspotter={props.removePrisspotter}
            addPermissions={props.addPermissions}
          ></PrisspotterActionButtonsComponent>
        </PrisspotterAccordionWrapper>
      ))}
    </Grid>
  );
};

export default PrisspotterList;

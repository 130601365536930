import React, { useState } from "react";
import { Dispatch, AnyAction, bindActionCreators } from "redux";
import { connect, ConnectedProps } from "react-redux";
import { Button, Grid, makeStyles, TextField } from "@material-ui/core";
import { useForm } from "react-hook-form";

import { ApplicationState } from "../../store/Store";
import BackImage from "../../assets/images/BackgroundPhotoVertical.jpg";
import { UnoxColors } from "../../constants/theme";
import { emailRegexp } from "../../services/Validation.patterns";
import { resetPassword } from "../../store/user/actionCreators";
import { Alert } from "@material-ui/lab";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles({
  paperContainer: {
    position: "fixed",
    left: 0,
    height: "100%",
    width: "100%",
    zIndex: 3,
    overflow: "hidden",
    borderRadius: 0,
  },
  picture: {
    objectFit: "cover",
    position: "fixed",
    left: 0,
    height: "100%",
    width: "100%",
    zIndex: 2,
  },
  cssLabel: {
    color: UnoxColors.White,
  },
  cssInput: {
    color: UnoxColors.White,
  },
  notchedOutline: {
    borderWidth: "1px",
    borderColor: UnoxColors.White,
  },
  marginedComponent: {
    marginTop: 5,
    marginBottom: 5,
  },
  formContainer: {
    paddingLeft: 50,
    paddingRight: 50,
    marginTop: 250,
    maxWidth: 400,
  },
  "@media (max-height: 440px)": {
    formContainer: {
      marginTop: 20,
    },
  },
});

type ForgotPasswordFormData = {
  UserName: string;
  Email: string;
};

const ForgotPasswordPage: React.FC<ForgotPasswordProps> = (props) => {
  const classes = useStyles();
  const history = useHistory();

  const [isPasswordReset, setIsPasswordReset] = useState(false);

  const formMethods = useForm<ForgotPasswordFormData>({ defaultValues: {} });

  const { register, handleSubmit, errors } = formMethods;

  const submit = async (data: ForgotPasswordFormData) => {
    await props.resetPassword(data.UserName, data.Email);
    setIsPasswordReset(true);
  };

  return (
    <>
      <img
        src={BackImage}
        className={classes.picture}
        alt="Login background"
      />
      <div className={classes.paperContainer}>
        <form
          onSubmit={handleSubmit(submit)}
          style={{ justifyContent: "center", display: "flex" }}
        >
          <Grid container direction="column" className={classes.formContainer}>
            <TextField
              variant="outlined"
              label="Indtast dit brugernavn"
              size="small"
              color="secondary"
              name="UserName"
              type="text"
              InputLabelProps={{
                classes: {
                  root: classes.cssLabel,
                },
              }}
              InputProps={{
                classes: {
                  notchedOutline: classes.notchedOutline,
                  root: classes.cssInput,
                },
              }}
              error={!!errors.UserName}
              inputRef={register({
                required: true,
              })}
              className={classes.marginedComponent}
            />
            <TextField
              variant="outlined"
              label="Indtast din e-mail"
              size="small"
              color="secondary"
              name="Email"
              type="email"
              InputLabelProps={{
                classes: {
                  root: classes.cssLabel,
                },
              }}
              InputProps={{
                classes: {
                  notchedOutline: classes.notchedOutline,
                  root: classes.cssInput,
                },
              }}
              error={!!errors.Email}
              inputRef={register({
                required: true,
                pattern: emailRegexp,
              })}
              className={classes.marginedComponent}
            />
            <Button
              color="primary"
              variant="contained"
              className={classes.marginedComponent}
              type="submit"
            >
              Send adgangskode
            </Button>
            <Button
              color="primary"
              variant="contained"
              className={classes.marginedComponent}
              onClick={() => history.goBack()}
            >
              Tilbage
            </Button>
            {isPasswordReset && (
              <Alert severity="success">
                Ny adgangskode sendes til din e-mail.
              </Alert>
            )}
          </Grid>
        </form>
      </div>
    </>
  );
};

const mapStateToProps = (state: ApplicationState) => ({});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators({ resetPassword }, dispatch);

const connector = connect(mapStateToProps, mapDispatchToProps);

type ForgotPasswordProps = ConnectedProps<typeof connector>;

export default connector(ForgotPasswordPage);

import { IGpsService } from "./IGpsService";
export class GpsService implements IGpsService {
  getCoordinates(timeout: number): Promise<Coordinates> {
    const options = {
      enableHighAccuracy: false,
      timeout: timeout,
      maximumAge: parseInt(process.env.REACT_APP_GPS_MAX_CACHE_TIME || "0"),
    };

    const coordinatesPromise = new Promise<Coordinates>((resolve, reject) =>
      navigator.geolocation.getCurrentPosition(
        (position: Position) => {
          resolve(position.coords);
        },
        (error:  PositionError) => {
          reject(error.message);
        },
        options
      )
    );

    const errorByTimeoutPromise = new Promise<Coordinates>(
      (resolve, reject) => {
        setTimeout(
          () => reject("coordinates timeout error"),
          parseInt(process.env.REACT_APP_GPS_TIMEOUT || "0")
        );
      }
    );

    return Promise.race([coordinatesPromise, errorByTimeoutPromise]);
  }
}

import { Dispatch } from "react";
import { AppAction } from "../../Models/Action";
import { RegisterPriceRequest } from "../../Models/DTO/RegisterPrice";
import { ApplicationState, IServices } from "../Store";
import {
  GET_LIST_PRICES_COMPLETE,
  GET_COMPETITOR_STATIONS,
  GET_COMPETITOR_STATIONS_COMPLETE,
  GET_MY_STATIONS,
  GET_MY_STATIONS_COMPLETE,
  REGISTER_PRICE_COMPLETE,
  EXPAND_COMPETITOR_STATION,
  EXPAND_MY_STATION,
  CLEAR_PRICE_REGISTRATION_STATION,
} from "./actions";
import { dispatchError, handleError } from "../actionCreatorsHelper";

export const getUnoxStations =
  () =>
  async (
    dispatch: Dispatch<AppAction>,
    getState: () => ApplicationState,
    services: IServices
  ) => {
    try {
      dispatch({
        type: GET_MY_STATIONS,
      });

      let localSettings = getState().system.localSettings;
      let coordinates = null;

      if (localSettings.SortUnoxStationsByLocation) {
        try {
          coordinates = await services.gpsService.getCoordinates(
            localSettings.LocationGettingTimeout
          );
        } catch (error) {
          console.log(error);
        }
      }

      const stationsResponse = await services.stationApiService.getMyStations(
        coordinates
      );

      if (stationsResponse.isError) {
        handleError(stationsResponse, services, dispatch);
      } else {
        dispatch({
          type: GET_MY_STATIONS_COMPLETE,
          payload: stationsResponse.data,
        });
      }
    } catch (error) {
      dispatchError(error, dispatch);
    }
  };

export const getCompetitorStations =
  () =>
  async (
    dispatch: Dispatch<AppAction>,
    getState: () => ApplicationState,
    services: IServices
  ) => {
    try {
      dispatch({
        type: GET_COMPETITOR_STATIONS,
      });

      let localSettings = getState().system.localSettings;
      let coordinates = null;

      if (localSettings.SortCompetitorStationsByLocation) {
        try {
          coordinates = await services.gpsService.getCoordinates(
            localSettings.LocationGettingTimeout
          );
        } catch (error) {
          console.log(error);
        }
      }
      const stationsResponse =
        await services.stationApiService.getCompetitorStations(coordinates);

      if (stationsResponse.isError) {
        handleError(stationsResponse, services, dispatch);
      } else {
        dispatch({
          type: GET_COMPETITOR_STATIONS_COMPLETE,
          payload: stationsResponse.data,
        });
      }
    } catch (error) {
      dispatchError(error, dispatch);
    }
  };

export const getListPrices =
  () =>
  async (dispatch: Dispatch<AppAction>, getState: any, services: IServices) => {
    try {
      const pricesResponse = await services.stationApiService.getListPrices();
      if (pricesResponse.isError) {
        handleError(pricesResponse, services, dispatch);
      } else {
        dispatch({
          type: GET_LIST_PRICES_COMPLETE,
          payload: pricesResponse.data,
        });
      }
    } catch (error) {
      dispatchError(error, dispatch);
    }
  };

export const registerPrice =
  (
    unleadedPrice: number,
    dieselPrice: number,
    stationId: string
  ) =>
  async (
    dispatch: Dispatch<AppAction>,
    getState: () => ApplicationState,
    services: IServices
  ) => {
    try {
      const dto: RegisterPriceRequest = {
        stationId: stationId,
        priceUnleaded: unleadedPrice,
        priceDiesel: dieselPrice,
      };

      const result = await services.stationApiService.registerPrice(dto);
      const currentUser = services.authService.restoreUser();
      if (result.isError) {
        handleError(result, services, dispatch);
      } else {
        dispatch({
          type: REGISTER_PRICE_COMPLETE,
          payload: {
            stationId: stationId,
            observationTime: result.data,
            actorId: currentUser?.actorNumber,
            dieselPrice: dieselPrice,
            unleadedPrice: unleadedPrice,
          },
        });
        setTimeout(() => {
          // ensure that station is still expanded in UI
          if (getState().station.expandedCompetitorStationId === stationId) {
            dispatch({
              type: CLEAR_PRICE_REGISTRATION_STATION,
            });
            dispatch({
              type: EXPAND_COMPETITOR_STATION,
              payload: null,
            });
          }
        }, parseInt(process.env.REACT_APP_PRICE_REGISTRATION_ALERT_TIMEOUT || "0"));
      }
    } catch (error) {
      dispatchError(error, dispatch);
    }
  };

export const expandCompetitorStation =
  (stationId: string | null) => (dispatch: Dispatch<AppAction>) => {
    dispatch({
      type: EXPAND_COMPETITOR_STATION,
      payload: stationId,
    });
  };

export const expandMyStation =
  (stationId: string | null) => (dispatch: Dispatch<AppAction>) => {
    dispatch({
      type: EXPAND_MY_STATION,
      payload: stationId,
    });
  };

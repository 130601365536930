import GetFerieafloesersResponse from '../../Models/DTO/GetFerieafloesersResponse';
import {
  FerieafloeserPermissions,
} from "../../Models/Ferieafloeser";

//export const GET_FERIEAFLOESERS = "GET_FERIEAFLOESERS";
export const GET_FERIEAFLOESERS_COMPLETE = "GET_FERIEAFLOESERS_COMPLETE";
//export const CREATE_FERIEAFLOESER = "CREATE_FERIEAFLOESER";
//export const CREATE_FERIEAFLOESER_COMPLETE = "CREATE_FERIEAFLOESER_COMPLETE";
//export const EDIT_FERIEAFLOESER = "EDIT_FERIEAFLOESER";
export const EDIT_FERIEAFLOESER_COMPLETE = "EDIT_FERIEAFLOESER_COMPLETE";
//export const REMOVE_FERIEAFLOESER = "REMOVE_FERIEAFLOESER";
export const REMOVE_FERIEAFLOESER_COMPLETE = "REMOVE_FERIEAFLOESER_COMPLETE";
//export const CHANGE_FERIEAFLOESER_PERMISSIONS =
//  "CHANGE_FERIEAFLOESER_PERMISSIONS";
export const CHANGE_FERIEAFLOESER_PERMISSIONS_COMPLETE =
  "CHANGE_FERIEAFLOESER_PERMISSIONS_COMPLETE";
//export const REMOVE_FERIEAFLOESER_PERMISSIONS =
//  "REMOVE_FERIEAFLOESER_PERMISSIONS";
export const REMOVE_FERIEAFLOESER_PERMISSIONS_COMPLETE =
  "REMOVE_FERIEAFLOESER_PERMISSIONS_COMPLETE";

interface GetFerieafloesersCompleteAction {
  type: typeof GET_FERIEAFLOESERS_COMPLETE;
  payload: GetFerieafloesersResponse;
}

interface EditFerieafloesersCompleteAction {
  type: typeof EDIT_FERIEAFLOESER_COMPLETE;
  payload: {actorNumber: number;
    email: string;
    phone: string};
}

interface RemoveFerieafloesersCompleteAction {
  type: typeof REMOVE_FERIEAFLOESER_COMPLETE;
  payload: number;
}

interface ChangeFerieafloeserPermissionsCompleteAction {
  type: typeof CHANGE_FERIEAFLOESER_PERMISSIONS_COMPLETE;
  payload: { actorNumber: number; permissions: FerieafloeserPermissions };
}

interface RemoveFerieafloeserPermissionsCompleteAction {
  type: typeof REMOVE_FERIEAFLOESER_PERMISSIONS_COMPLETE;
  payload: number;
}

export type FerieafloeserActionTypes =
  | GetFerieafloesersCompleteAction
  | EditFerieafloesersCompleteAction
  | RemoveFerieafloesersCompleteAction
  | ChangeFerieafloeserPermissionsCompleteAction
  | RemoveFerieafloeserPermissionsCompleteAction;

import { Contact } from "../../Models/Contact";
import { LOGOFF_COMPLETE, UserActionTypes } from "../user/actions";
import { ContactActionTypes, GET_CONTACTS_COMPLETE } from "./actions";

export interface ContactState {
  contacts: Contact[];
}

export const contactInitialState: ContactState = {
  contacts: [],
};

const contactReducer = (
  state = contactInitialState,
  action: ContactActionTypes | UserActionTypes
): ContactState => {
  switch (action.type) {
    case GET_CONTACTS_COMPLETE: {
      return {
        ...state,
        contacts: action.payload,
      };
    }
    case LOGOFF_COMPLETE: {
      return contactInitialState;
    }
    default:
      return state;
  }
};

export default contactReducer;

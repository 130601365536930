import React from "react";
import {
  Button,
  Grid,
  Typography,
} from "@material-ui/core";

import { UnoxColors } from "../../../constants/theme";
import { StationPermission } from "../../../Models/StationPermission";

type PrisspotterPermissionsProps = {
  permissions: StationPermission[];
  removePermissions: () => void;
};

const PrisspotterPermissionsComponent: React.FC<PrisspotterPermissionsProps> = (
  props
) => {
  return (
    <Grid container direction="column">
      {/* Show permission list */}
      <Typography variant="body2" style={{ fontWeight: "bold" }}>
        Stationer:
      </Typography>
      {props.permissions.map((station) => (
        <Typography variant="body2" key={station.stationNumber}>
          {station.stationName || station.stationNumber}
        </Typography>
      ))}
      <Button
        style={{
          backgroundColor: UnoxColors.Red,
          color: UnoxColors.White,
          maxWidth: 400,
          marginTop: 10,
        }}
        variant="contained"
        onClick={() => props.removePermissions()}
      >
        Slet aftale
      </Button>
    </Grid>
  );
};

export default PrisspotterPermissionsComponent;

import UiMessage from "../../Models/UiMessage";
import { LocalSettings } from "../../Models/LocalSettings";

export const ALERT = "ALERT";
export const CLEAR_ALERT = "CLEAR_ALERT";
export const SHOW_SPINNER = "SHOW_SPINNER";
export const SET_LOCAL_SETTINGS = "SET_LOCAL_SETTINGS";

interface AlertAction {
  type: typeof ALERT;
  payload: UiMessage;
}

interface ClearAlertAction {
  type: typeof CLEAR_ALERT;
}

interface ShowSpinnerAction {
  type: typeof SHOW_SPINNER;
}

interface SetLocalSettingsAction {
  type: typeof SET_LOCAL_SETTINGS;
  payload: LocalSettings;
}

export type SystemActionTypes =
  | AlertAction
  | ClearAlertAction
  | ShowSpinnerAction
  | SetLocalSettingsAction;

import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import { CheckBox as CheckBoxIcon, DoneAll } from "@material-ui/icons";

import { UnoxColors } from "../../../constants/theme";
import { FerieafloeserPermissions } from "../../../Models/Ferieafloeser";
import { getShortIsoDateString } from "../../../services/DateFormatter";
import { StationPermission } from "../../../Models/StationPermission";

type StationPermissionSelection = StationPermission & {
  isSelected: boolean;
};

type AddPermissionsProps = {
  availablePermissionStations: StationPermission[];
  changePermissions: (permissions: FerieafloeserPermissions) => void;
  cancel: () => void;
};

const AddPermissions: React.FC<AddPermissionsProps> = (props) => {
  const [newPermissionFrom, setNewPermissionFrom] = useState(new Date());
  const [newPermissionTo, setNewPermissionTo] = useState(new Date());
  const [availableStations, setAvailableStations] = useState<
    StationPermissionSelection[]
  >([]);

  useEffect(() => {
    const mappedStations = props.availablePermissionStations.map((st) => {
      return { ...st, isSelected: false };
    });
    setAvailableStations(mappedStations);
  }, [props.availablePermissionStations]);

  const handleAddPermissions = () => {
    const list = availableStations.filter((st) => st.isSelected);
    const data: FerieafloeserPermissions = {
      stationPermissions: list,
      startDate: newPermissionFrom,
      endDate: newPermissionTo,
    };
    props.changePermissions(data);
  };

  const handleCheckboxCheck = (stationNumber: number) => {
    const newStations = availableStations.map((st) =>
      st.stationNumber === stationNumber
        ? { ...st, isSelected: !st.isSelected }
        : st
    );
    setAvailableStations(newStations);
  };

  const selectAll = () => {
    const newStations = availableStations.map((st) => ({
      ...st,
      isSelected: true,
    }));
    setAvailableStations(newStations);
  };

  return (
    <Grid container direction="column">
      <Typography variant="h5">Opret ny aftale</Typography>
      <Grid
        container
        direction="row"
        alignItems="center"
        style={{ marginTop: 10 }}
      >
        <Typography
          variant="body2"
          style={{ fontWeight: "bold", marginRight: 5, minWidth: 30 }}
        >
          Fra:
        </Typography>
        <TextField
          type="date"
          inputProps={{
            min: getShortIsoDateString(new Date()),
            style: {
              fontSize: 14,
            },
          }}
          style={{ fontSize: 10 }}
          value={getShortIsoDateString(newPermissionFrom)}
          onChange={(e) => {
            const newDate = new Date(e.target.value);
            setNewPermissionFrom(newDate);
            if (newDate > newPermissionTo) {
              setNewPermissionTo(newDate);
            }
          }}
        />
      </Grid>
      <Grid container direction="row" alignItems="center">
        <Typography
          variant="body2"
          style={{ fontWeight: "bold", marginRight: 5, minWidth: 30 }}
        >
          Til:
        </Typography>
        <TextField
          type="date"
          inputProps={{
            min: getShortIsoDateString(newPermissionFrom),
            style: {
              fontSize: 14,
            },
          }}
          value={getShortIsoDateString(newPermissionTo)}
          onChange={(e) => setNewPermissionTo(new Date(e.target.value))}
        />
      </Grid>
      <Typography variant="body2" style={{ marginTop: 10 }}>
        Marker de stationer din prisspotter skal passe:
      </Typography>

      {availableStations.map((stationSelection) => (
        <FormControlLabel
          key={stationSelection.stationNumber}
          control={
            <Checkbox
              checked={stationSelection.isSelected}
              style={{ height: 10 }}
              icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
              checkedIcon={<CheckBoxIcon fontSize="small" />}
              onChange={() =>
                handleCheckboxCheck(stationSelection.stationNumber)
              }
              color="primary"
            />
          }
          label={
            <Typography variant="body2">
              {stationSelection.stationName}
            </Typography>
          }
        />
      ))}
      <Button onClick={() => selectAll()} size="small" startIcon={<DoneAll />} style={{maxWidth: 184, paddingLeft: 0}}>
        Vælg alle stationer
      </Button>
      <Grid
        item
        container
        direction="row"
        justify="space-between"
        style={{ marginTop: 10 }}
      >
        <Button
          style={{
            backgroundColor: UnoxColors.Red,
            color: UnoxColors.White,
            maxWidth: 160,
            marginRight: 10,
            flex: 1,
          }}
          variant="contained"
          onClick={() => props.cancel()}
        >
          Annuller
        </Button>
        <Button
          style={{
            backgroundColor: UnoxColors.Green,
            color: UnoxColors.White,
            maxWidth: 160,
            flex: 1,
          }}
          variant="contained"
          onClick={() => handleAddPermissions()}
        >
          Opret aftale
        </Button>
      </Grid>
    </Grid>
  );
};

export default AddPermissions;

import React from "react";
import {
  Button,
  Grid,
  Typography,
} from "@material-ui/core";

import { UnoxColors } from "../../../constants/theme";
import { FerieafloeserPermissions } from "../../../Models/Ferieafloeser";
import { getShortDateString } from "../../../services/DateFormatter";

type FerieafloeserPermissionsProps = {
  permissions: FerieafloeserPermissions;
  removePermissions: () => void;
};

const FerieafloeserPermissionsComponent: React.FC<FerieafloeserPermissionsProps> = (
  props
) => {
  return (
    <Grid style={{ marginTop: 10 }} container direction="column">
      {/* Show permission list */}
      <Grid container direction="row">
        <Typography
          variant="body2"
          style={{ fontWeight: "bold", minWidth: 30 }}
        >
          Fra:
        </Typography>
        <Typography variant="body2">
          {getShortDateString(props.permissions.startDate)}
        </Typography>
      </Grid>
      <Grid container direction="row">
        <Typography
          variant="body2"
          style={{ fontWeight: "bold", minWidth: 30 }}
        >
          Til:
        </Typography>
        <Typography variant="body2">
          {getShortDateString(props.permissions.endDate)}
        </Typography>
      </Grid>
      <Typography variant="body2" style={{ fontWeight: "bold" }}>
        Stationer:
      </Typography>
      {props.permissions.stationPermissions.map((station) => (
        <Typography variant="body2" key={station.stationNumber}>
          {station.stationName || station.stationNumber}
        </Typography>
      ))}
      <Button
        style={{
          backgroundColor: UnoxColors.Red,
          color: UnoxColors.White,
          maxWidth: 400,
          marginTop: 10,
        }}
        variant="contained"
        onClick={() => props.removePermissions()}
      >
        Slet aftale
      </Button>
    </Grid>
  );
};

export default FerieafloeserPermissionsComponent;

import { Dispatch } from "react";
import { AppAction } from "../../Models/Action";
import { ApplicationState, IServices } from "../Store";
import {
  CHANGE_AVATAR_COMPLETE,
  CHANGE_PASSWORD_COMPLETE,
  CHANGE_AVATAR,
} from "./actions";
import {
  CHANGE_CONTACT_INFO,
  LOGIN_COMPLETE,
  LOGOFF_COMPLETE,
  CHANGE_CONTACT_INFO_COMPLETE,
} from "./actions";
import { ALERT, SHOW_SPINNER } from "../system/actions";
import { dispatchError, handleError } from '../actionCreatorsHelper';

export const restoreUser = () => (
  dispatch: Dispatch<AppAction>,
  getState: any,
  services: IServices
) => {
  try {
    const user = services.authService.restoreUser();
    if (user) {
      dispatch({
        type: LOGIN_COMPLETE,
        payload: user,
      });
      return true;
    } else {
      services.authService.logOff();
      return false;
    }
  } catch (error) {
    return false;
  }
};

export const loginUser = (name: string, password: string) => async (
  dispatch: Dispatch<AppAction>,
  getState: any,
  services: IServices
) => {
  try {
    dispatch({
      type: SHOW_SPINNER,
    });
    
    const tokensSuccess = await services.authService.loadToken(name, password);
    if (!tokensSuccess) {
      dispatchError("Forkert brugernavn eller kodeord", dispatch);
      return false;
    }

    const userResult = await services.userApiService.getUserDetails();
    if (userResult.isError || !userResult.data) {
      dispatchError(userResult.error, dispatch);
      return false;
    }
    services.authService.storeUser(userResult.data);

    dispatch({
      type: LOGIN_COMPLETE,
      payload: userResult.data,
    });

    return true;
  } catch (error) {
    dispatchError(error, dispatch);
    return false;
  }
};

export const logoffUser = () => async (
  dispatch: Dispatch<AppAction>,
  getState: any,
  services: IServices
) => {
  try {
    services.authService.logOff();

    dispatch({
      type: LOGOFF_COMPLETE,
    });
  } catch (error) {
    dispatchError(error, dispatch);
  }
};

export const resetPassword = (name: string, email: string) => async (
  dispatch: Dispatch<AppAction>,
  getState: any,
  services: IServices
) => {
  await services.userApiService.resetPassword(name, email);
};

export const changeContactInfo = (phone: string, email: string) => async (
  dispatch: Dispatch<AppAction>,
  getState: () => ApplicationState,
  services: IServices
) => {
  try {
    dispatch({
      type: CHANGE_CONTACT_INFO,
    });

    const result = await services.userApiService.changeContactInfo({
      phone,
      email,
    });
    if (result.isError) {
      handleError(result, services, dispatch);
    } else {
      dispatch({
        type: CHANGE_CONTACT_INFO_COMPLETE,
        payload: { phone, email },
      });
      const updatedUser = getState().user.currentUser;
      if (updatedUser) {
        services.authService.storeUser(updatedUser);
      }
    }
  } catch (error) {
    dispatchError(error, dispatch);
  }
};

export const changePassword = (
  oldPassword: string,
  newPassword: string
) => async (
  dispatch: Dispatch<AppAction>,
  getState: () => ApplicationState,
  services: IServices
) => {
  try {
    dispatch({
      type: CHANGE_CONTACT_INFO,
    });

    const result = await services.userApiService.changePassword({
      oldPassword,
      newPassword,
    });
    if (result.isError) {
      handleError(result, services, dispatch);
    } else {
      dispatch({
        type: CHANGE_PASSWORD_COMPLETE,
      });
      dispatch({
        type: ALERT,
        payload: { text: "Kodeordet er blevet ændret", type: "success" },
      });
    }
  } catch (error) {
    dispatchError(error, dispatch);
  }
};

export const changeAvatar = (picture: File) => async (
  dispatch: Dispatch<AppAction>,
  getState: () => ApplicationState,
  services: IServices
) => {
  try {
    dispatch({
      type: CHANGE_AVATAR,
    });

    const result = await services.userApiService.changeAvatar(picture);
    if (result.isError) {
      handleError(result, services, dispatch);
    } else {
      dispatch({
        type: CHANGE_AVATAR_COMPLETE,
      });
      dispatch({
        type: ALERT,
        payload: { text: "Profilbillede er gemt", type: "success" },
      });
    }
  } catch (error) {
    dispatchError(error, dispatch);
  }
};

import React from "react";
import { Button, Grid, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { Dispatch, AnyAction, bindActionCreators } from "redux";
import { connect, ConnectedProps } from "react-redux";

import { UnoxColors } from "../../constants/theme";
import TopBar from "../../components/TopBar";
import { PagePath, PageType } from "../../Models/PageType";
import { ApplicationState } from "../../store/Store";
import { UserRole } from "../../Models/UserRole";

const HomePage: React.FC<HomePageProps> = (props) => {
  const history = useHistory();

  type HomePageButtonProps = {
    path: PagePath;
  };
  const HomePageButton: React.FC<HomePageButtonProps> = ({
    path,
    children,
  }) => {
    return (
      <Button
        variant="contained"
        size="large"
        style={{
          marginTop: 5,
          marginBottom: 5,
          background: UnoxColors.White,
          maxWidth: 350,
          width: "100%",
          //textTransform: "none",
        }}
        onClick={() => history.push(path)}
      >
        {children}
      </Button>
    );
  };

  return (
    <Grid container>
      <TopBar currentPage={PageType.Home}></TopBar>
      <Grid item container justify="space-between" style={{ marginBottom: 10 }}>
        <Typography variant="h5">Frontpage</Typography>
      </Grid>
      <Grid item container direction="column" alignItems="center">
        <HomePageButton path={PagePath.PriceReporting}>
          Prisindrapportering
        </HomePageButton>
        <HomePageButton path={PagePath.MyStations}>
          Mine stationer
        </HomePageButton>
        {props.user?.role === UserRole.DistrictPartner && (
          <>
            <HomePageButton path={PagePath.Ferieafloesere}>
              Ferieafløsere
            </HomePageButton>
            <HomePageButton path={PagePath.Prisspottere}>
              Prisspottere
            </HomePageButton>
          </>
        )}
        <HomePageButton path={PagePath.Contacts}>
          Ring rigtigt
        </HomePageButton>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  user: state.user.currentUser,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators({}, dispatch);

const connector = connect(mapStateToProps, mapDispatchToProps);

type HomePageProps = ConnectedProps<typeof connector>;

export default connector(HomePage);

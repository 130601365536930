import React, { useEffect, useRef, useState } from "react";
import { Dispatch, AnyAction, bindActionCreators } from "redux";
import { connect, ConnectedProps } from "react-redux";
import {
  Button,
  Collapse,
  Grid,
  GridList,
  Snackbar,
  Typography,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import { ApplicationState } from "../../store/Store";
import { clearAlert } from "../../store/system/actionCreators";
import TopBar from "../../components/TopBar";
import { PageType } from "../../Models/PageType";
import LoadingOverlay from "../../components/LoadingOverlay";
import { getContacts } from "../../store/contacts/actionCreators";
import ContactRecord from "./components/ContactRecord";

const ContactsPage: React.FC<ContactsConnectedProps> = (props) => {
  const getContacts = props.getContacts;
  useEffect(() => {
    getContacts();
  }, [getContacts]);

  return (
    <>
      <Grid container direction="column">
        <TopBar currentPage={PageType.Contacts}></TopBar>
        <Typography variant="h5" style={{marginBottom: 10}}>Ring Rigtigt</Typography>
        {props.contacts.map((contact) => (
          <ContactRecord contact={contact} key={contact.id} />
        ))}
      </Grid>
      <Snackbar
        autoHideDuration={parseInt(process.env.REACT_APP_ALERT_TIMEOUT || "0")}
        open={!!props.alert}
        onClose={() => props.clearAlert()}
      >
        <Alert severity={props.alert?.type}>{props.alert?.text}</Alert>
      </Snackbar>
      <LoadingOverlay loading={props.loading} />
    </>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  alert: state.system.alert,
  loading: state.system.loading,
  contacts: state.contact.contacts,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      getContacts,
      clearAlert,
    },
    dispatch
  );

const connector = connect(mapStateToProps, mapDispatchToProps);

type ContactsConnectedProps = ConnectedProps<typeof connector>;

export default connector(ContactsPage);

import { Dispatch } from "react";
import { AppAction } from "../../Models/Action";
import { IServices } from "../Store";
import { GET_CONTACTS_COMPLETE } from "./actions";
import { SHOW_SPINNER } from "../system/actions";
import ApiResponse from "../../Models/ApiResponse";
import { dispatchError, handleError } from "../actionCreatorsHelper";
import { Contact } from "../../Models/Contact";

export const getContacts =
  () =>
  async (dispatch: Dispatch<AppAction>, getState: any, services: IServices) => {
    try {
      dispatch({
        type: SHOW_SPINNER,
      });
      const response = await services.contactApiService.getContacts();
      const onSuccess = (data: Contact[]) => {
        dispatch({
          type: GET_CONTACTS_COMPLETE,
          payload: data,
        });
      };
      processResponse(response, services, dispatch, onSuccess);
    } catch (error) {
      dispatchError(error, dispatch);
    }
  };

function processResponse<T>(
  response: ApiResponse<T>,
  services: IServices,
  dispatch: Dispatch<AppAction>,
  onsuccess: (data: T) => void
) {
  if (response.isError) {
    handleError(response, services, dispatch);
  } else {
    onsuccess(response.data!);
  }
}

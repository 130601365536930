import React, { useCallback, useEffect, useState } from "react";
import { Dispatch, AnyAction, bindActionCreators } from "redux";
import { connect, ConnectedProps } from "react-redux";
import { Grid, makeStyles, Snackbar, Typography } from "@material-ui/core";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import { Cached } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";

import { ApplicationState } from "../../store/Store";
import {
  getCompetitorStations,
  registerPrice,
  expandCompetitorStation
} from "../../store/station/actionCreators";
import { clearAlert } from "../../store/system/actionCreators";
import { CompetitorStation, ReportingType } from "../../Models/Station";
import CompetitorStationsList from "./components/CompetitorStationsList";
import { UnoxColors } from "../../constants/theme";
import TopBar from "../../components/TopBar";
import { PageType } from "../../Models/PageType";
import LoadingOverlay from "../../components/LoadingOverlay";

const useStyles = makeStyles({
  listButton: {
    backgroundColor: "white",
    border: "1px solid #9d9d9d",
    marginLeft: "0px!important",
    flex: 1,
    fontWeight: "bold",
    color: UnoxColors.Black,
    "&.Mui-selected": {
      backgroundColor: UnoxColors.Orange,
    },
    "&.Mui-selected:hover": {
      backgroundColor: UnoxColors.Orange,
    },
  },
});

const PriceReportingPage: React.FC<PriceReportingConnectedProps> = (props) => {
  const getCompetitorStations = props.getCompetitorStations;
  useEffect(() => {
    getCompetitorStations();
  }, [getCompetitorStations]);

  const [activeListType, setActiveListType] = useState<ReportingType>(
    ReportingType.Must
  );

  const [activeList, setActiveList] = useState<CompetitorStation[]>(
    props.competitorLists.MustList
  );

  const handleListTypeChange = useCallback(
    (_: any, newListType: ReportingType) => {
      setActiveListType(newListType);
      switch (newListType) {
        case ReportingType.Must:
          setActiveList(props.competitorLists.MustList);
          break;
        case ReportingType.May:
          setActiveList(props.competitorLists.MayList);
          break;
        case ReportingType.Can:
          setActiveList(props.competitorLists.CanList);
          break;
      }
    },
    [setActiveListType, props.competitorLists]
  );

  useEffect(() => {
    handleListTypeChange(null, activeListType);
  }, [props.competitorLists, activeListType, handleListTypeChange]);

  const handlePriceSubmit = async (
    unleadedPrice: number,
    dieselPrice: number,
    stationId: string
  ) => {
    props.registerPrice(unleadedPrice, dieselPrice, stationId);
  };

  const classes = useStyles();

  return (
    <>
      <Grid container>
        <TopBar currentPage={PageType.PriceReporting}></TopBar>
        <Grid
          item
          container
          justify="space-between"
          style={{ marginBottom: 10 }}
        >
          <Typography variant="h5">Prisindrapportering</Typography>
          <Cached onClick={() => props.getCompetitorStations()} />
        </Grid>
        <Grid item container>
          <Typography variant="body2">Filtrer stationer:</Typography>
          <ToggleButtonGroup
            value={activeListType}
            exclusive
            onChange={handleListTypeChange}
            size="small"
            style={{ width: "100%", marginTop: 5 }}
          >
            <ToggleButton
              className={classes.listButton}
              value={ReportingType.Must}
            >
              Skal
            </ToggleButton>
            <ToggleButton
              className={classes.listButton}
              value={ReportingType.May}
            >
              Må
            </ToggleButton>
            <ToggleButton
              className={classes.listButton}
              value={ReportingType.Can}
            >
              Kan
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
        <Grid item style={{ marginTop: 20, width: "100%" }}>
          <CompetitorStationsList
            stations={activeList}
            onSubmit={handlePriceSubmit}
            expandedStationId={props.expandedStationId}
            expandCompetitorStation={props.expandCompetitorStation}
            successfullyReportedStationId={props.successfullyReportedStationId}
          ></CompetitorStationsList>
        </Grid>
      </Grid>
      <Snackbar
        autoHideDuration={parseInt(process.env.REACT_APP_ALERT_TIMEOUT || "0")}
        open={!!props.alert}
        onClose={() => props.clearAlert()}
      >
        <Alert severity={props.alert?.type}>{props.alert?.text}</Alert>
      </Snackbar>
      <LoadingOverlay loading={props.loading} />
    </>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  competitorLists: state.station.competitorStations,
  expandedStationId: state.station.expandedCompetitorStationId,
  successfullyReportedStationId: state.station.successfullyReportedCompetitorStationId,
  alert: state.system.alert,
  loading: state.system.loading,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      getCompetitorStations,
      clearAlert,
      registerPrice,
      expandCompetitorStation,
    },
    dispatch
  );

const connector = connect(mapStateToProps, mapDispatchToProps);

type PriceReportingConnectedProps = ConnectedProps<typeof connector>;

export default connector(PriceReportingPage);

export interface Station {
  name: string;
  address: string;
  stationNumber: string;
  lastUnleaded: number;
  lastDiesel: number;
  updated: Date;
  id: string;
}

export interface UnoxStation extends Station {
  isBonus: boolean;
  observation: Observation | null;
}

export interface CompetitorStation extends Station {
  reportingType: ReportingType;  
  chain: string;
  lastPriceReporterId: string;
}

export enum ReportingType {
  Must = "Must",
  May = "May",
  Can = "Can",
};

export interface Observation {
  ulObservation_dateTime: Date | string | null;
  ulObservation_stationName: string | null;
  ulObservation_price: number | null;
  dieselObservation_dateTime: Date | string | null;
  dieselObservation_stationName: string | null;
  dieselObservation_price: number | null;
}
import React from "react";
import { Grid } from "@material-ui/core";

import {
  Ferieafloeser,
  FerieafloeserPermissions,
} from "../../../Models/Ferieafloeser";
import { StationPermission } from "../../../Models/StationPermission";
import FerieafloeserActionButtonsComponent from "./FerieafloeserActionButtonsComponent";
import FerieafloeserAccordionWrapper from "./FerieafloeserAccordionWrapper";

type FerieafloeserListProps = {
  ferieafloesers: Ferieafloeser[];
  permissionStations: StationPermission[];
  expandedActor: number | null;
  setExpandedActor: (actorNumber: number | null) => void;
  editFefieafloeser: (
    actorNumber: number,
    password: string,
    email: string,
    phone: string
  ) => void;
  removeFerieafloeser: (actorNumber: number) => void;
  changePermissions: (
    actorNumber: number,
    permissions: FerieafloeserPermissions
  ) => void;
};

const FerieafloeserList: React.FC<FerieafloeserListProps> = (props) => {
  const getUserPermissionSummary = (ferieafloeser: Ferieafloeser) => {
    if (!ferieafloeser.permissions || ferieafloeser.permissions.length === 0) {
      return "Ingen aftaler booket";
    }
    if (ferieafloeser.permissions.length > 0) {
      return "Aftaler booket";
    }
    return "";
  };

  return (
    <Grid container justify="center">
      {props.ferieafloesers.map((ferieafloeser: Ferieafloeser) => (
        <FerieafloeserAccordionWrapper
          ferieafloeser={ferieafloeser}
          uniqueKey={ferieafloeser.actorNumber}
          key={ferieafloeser.actorNumber}
          expandedKey={props.expandedActor}
          permissionsSummary={getUserPermissionSummary(ferieafloeser)}
          setExpandedKey={props.setExpandedActor}
          editFefieafloeser={(
            actorNumber: number,
            password: string,
            email: string,
            phone: string
          ) => props.editFefieafloeser(actorNumber, password, email, phone)}
        >
          <FerieafloeserActionButtonsComponent
            actorNumber={ferieafloeser.actorNumber}
            availablePermissionStations={props.permissionStations}
            removeFerieafloeser={props.removeFerieafloeser}
            changePermissions={props.changePermissions}
          ></FerieafloeserActionButtonsComponent>
        </FerieafloeserAccordionWrapper>
      ))}
    </Grid>
  );
};

export default FerieafloeserList;

import React, { useEffect, useRef } from "react";
import {
  createStyles,
  makeStyles,
  TextField,
  Theme,
  useMediaQuery,
} from "@material-ui/core";
import { UnoxColors } from "../../../constants/theme";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cssInput: {
      color: UnoxColors.Black,
    },
    notchedOutline: {
      borderWidth: "1px",
      borderColor: UnoxColors.Black,
    },
  })
);

type PriceTextFieldProps = {
  pricePart: number | string;
  // true if it is a first part of price
  isKroner: boolean;
  switchToNextControl: () => void;
  isFocused: boolean;
  onFocus: () => void;
  onPriceChange: (val: number | string) => void;
};

const maxAllowedKroner = 20;
const maxAllowedOere = 99;

const PriceTextField: React.FC<PriceTextFieldProps> = (props) => {
  const classes = useStyles();
  const textInput = useRef<HTMLInputElement>(null);
  const isSmallScreen = useMediaQuery("(max-width:355px)");

  useEffect(() => {
    if (props.isFocused && textInput && textInput.current) {
      textInput.current.focus();
    }
  }, [props.isFocused]);

  const isValueValid = (value: number) => {
    if (value < 0) {
      return false;
    }
    if (props.isKroner) {
      return value <= maxAllowedKroner;
    } else {
      return value <= maxAllowedOere;
    }
  };

  const onChange = (text: string) => {
    if (text === "") {
      props.onPriceChange(text);
      return;
    }

    const newValue = parseInt(text);

    if (!isValueValid(newValue)) {
      props.onPriceChange(props.pricePart);
      return;
    }

    props.onPriceChange(newValue);

    if (text.length >= 2 || (props.isKroner && newValue >= 3)) {
      props.switchToNextControl();
    }
  };

  return (
    <TextField
      type="number"
      InputLabelProps={{
        shrink: true,
      }}
      variant="outlined"
      value={props.pricePart}
      style={{ width: isSmallScreen ? 35 : 60 }}
      size="small"
      inputRef={textInput}
      onFocus={(e) => props.onFocus()}
      onChange={(e) => onChange(e.target.value)}
      inputProps={{
        inputmode: "tel",
        min: 0,
        max: props.isKroner ? maxAllowedKroner : maxAllowedOere,
        maxLength: 2,
        classes: {
          notchedOutline: classes.notchedOutline,
          root: classes.cssInput,
        },
        style: {
          color: UnoxColors.Black,
          textAlign: "center",
          fontWeight: "bold",
          paddingLeft: isSmallScreen ? 5 : "auto",
          paddingRight: isSmallScreen ? 5 : "auto",
        },
      }}
    />
  );
};

export default PriceTextField;

import React, { useState } from "react";
import {
  Button,
  Collapse,
  Grid,
} from "@material-ui/core";

import { UnoxColors } from "../../../constants/theme";
import { StationPermission } from '../../../Models/StationPermission';
import ConfirmationDialog from "../../../components/ConfirmationDialog";
import AddPermissions from "./AddPermissions";

type PrisspotterPermissionsProps = {
  actorNumber: number;
  availablePermissionStations: StationPermission[];
  removePrisspotter: (actorNumber: number) => void;
  addPermissions: (
    actorNumber: number,
    permissions: StationPermission[]
  ) => void;
};

const PrisspotterActionButtonsComponent: React.FC<PrisspotterPermissionsProps> = (
  props
) => {
  const [isAddingPermissions, setIsAddingPermissions] = useState(false);
  const [showRemoveDialog, setShowRemoveDialog] = useState(false);

  const handleAddPermissions = (data: StationPermission[]) => {
    props.addPermissions(props.actorNumber, data);
    setIsAddingPermissions(false);
  };

  return (
    <Grid style={{ marginTop: 10 }}>
      {/* Show create permission/remove actor buttons */}
      {!isAddingPermissions && (
        <Grid item container direction="row" justify="space-between">
          <Button
            style={{
              backgroundColor: UnoxColors.Red,
              color: UnoxColors.White,
              maxWidth: 160,
              marginRight: 10,
              flex: 1,
            }}
            variant="contained"
            onClick={() => setShowRemoveDialog(true)}
          >
            Slet
          </Button>
          <Button
            style={{
              backgroundColor: UnoxColors.Green,
              color: UnoxColors.White,
              maxWidth: 160,
              flex: 1,
            }}
            variant="contained"
            onClick={() => {
              setIsAddingPermissions(true);
            }}
          >
            Tilføj aftale
          </Button>
        </Grid>
      )}

      {/* Permission adding form */}
      <Collapse
        in={isAddingPermissions}
        onEntered={() => {}}
      >
        <AddPermissions
          availablePermissionStations={props.availablePermissionStations}
          addPermissions={handleAddPermissions}
          cancel={() => setIsAddingPermissions(false)}
        ></AddPermissions>
      </Collapse>
      {/* confirm delete actor  */}
      <ConfirmationDialog
        message="Vil du slette Prisspotter?"
        open={showRemoveDialog}
        confirm={() => {
          props.removePrisspotter(props.actorNumber);
          setShowRemoveDialog(false);
        }}
        cancel={() => setShowRemoveDialog(false)}
      ></ConfirmationDialog>
    </Grid>
  );
};

export default PrisspotterActionButtonsComponent;

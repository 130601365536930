//dd.MM.yyyy
export const getShortDateString = (date: Date | string) => {
  return new Date(date).toLocaleString("da-dk", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });
};

//dd.MM.yyy hh.mm
export const getLongDateString = (date: Date | string) => {
  return new Date(date).toLocaleString("da-dk", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });
};

//yyyy-MM-dd 
export const getShortIsoDateString = (date: Date | string) => {
  return new Date(date).toISOString().substr(0,10);
};

import React from "react";
import { Grid } from "@material-ui/core";

import { Prisspotter } from "../../../Models/Prisspotter";
import PrisspotterPermissionsComponent from "./PrisspotterPermissionsComponent";
import PrisspotterAccordionWrapper from "./PrisspotterAccordionWrapper";
import { StationPermission } from "../../../Models/StationPermission";

type PrisspotterListProps = {
  prisspotters: Prisspotter[];

  expandedActor: number | null;
  setExpandedActor: (actorNumber: number | null) => void;
  editPrisspotter: (actorNumber: number, password: string) => void;
  removePermissions: (
    actorNumber: number,
    permissions: StationPermission[]
  ) => void;
};

const PrisspotterPermissionList: React.FC<PrisspotterListProps> = (props) => {
  const getPermissionSummary = (permissions: StationPermission[]) => {
    if (permissions.length === 1) {
      return "(1 station)";
    }
    return `(${permissions.length} stationer)`;
  };

  const getUniqueKey = (prisspotter: Prisspotter, permissionIndex: number) =>
    prisspotter.actorNumber + (permissionIndex + 1) * 10000;

  return (
    <Grid container justify="center">
      {props.prisspotters.map((prisspotter: Prisspotter, index: number) => (
        <PrisspotterAccordionWrapper
          prisspotter={prisspotter}
          uniqueKey={getUniqueKey(prisspotter, index)}
          key={getUniqueKey(prisspotter, index)}
          expandedKey={props.expandedActor}
          permissionsSummary={getPermissionSummary(prisspotter.permissions)}
          setExpandedKey={props.setExpandedActor}
          editPrisspotter={(actorNumber: number, password: string) =>
            props.editPrisspotter(actorNumber, password)
          }
        >
          <PrisspotterPermissionsComponent
            permissions={prisspotter.permissions}
            removePermissions={() =>
              props.removePermissions(
                prisspotter.actorNumber,
                prisspotter.permissions
              )
            }
          ></PrisspotterPermissionsComponent>
        </PrisspotterAccordionWrapper>
      ))}
    </Grid>
  );
};

export default PrisspotterPermissionList;

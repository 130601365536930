import IHttpService from "./IHttpService";
import ApiResponse from "../../Models/ApiResponse";
import FuelServerResponse from "../../Models/DTO/FuelServerResponse";
import ISubordinateUserApiService from "./ISubordinateUserApiService";
import GetFerieafloesersResponse from "../../Models/DTO/GetFerieafloesersResponse";
import { UserRole } from "../../Models/UserRole";
import GetPrisspottersResponse from '../../Models/DTO/GetPrisspottersResponse';
import { Ferieafloeser } from "../../Models/Ferieafloeser";
import { Prisspotter } from '../../Models/Prisspotter';
  
export class SubordinateUserApiService implements ISubordinateUserApiService {
  constructor(private httpService: IHttpService) {}

  async getFerieafloesers(): Promise<ApiResponse<GetFerieafloesersResponse>> {
    const url = `${process.env.REACT_APP_SERVICE}api/user/GetFerieafloesers`;
    const response = await this.httpService.get<
      FuelServerResponse<GetFerieafloesersResponse>
    >(url);

    if (response.isError || !response.data) {
      return { isError: true, IsNotAuthorized: response.IsNotAuthorized };
    }

    const data = response.data;
    if (data.isOk) {
      return { isError: false, data: data.result };
    } else {
      return {
        isError: true,
        error:
          "Kan ikke indlæse Ferieafløser. " + (data.errorDescription ?? ""),
      };
    }
  }
  
  createFerieafloeser(
    actor: Ferieafloeser,
    password: string
  ): Promise<ApiResponse<number>> {
    return this.createSubordinateUser(actor.name, actor.userName, actor.email, actor.phone, password, UserRole.Ferieafloeser);
  }

  editFefieafloeser(
    actorNumber: number,
    password: string,
    email: string,
    phone: string
  ): Promise<ApiResponse<void>> {
    return this.editSubordinateUser(actorNumber,password,email,phone);
  }

  async getPrisspotters(): Promise<ApiResponse<GetPrisspottersResponse>> {
    const url = `${process.env.REACT_APP_SERVICE}api/user/GetPrisspotters`;
    const response = await this.httpService.get<
      FuelServerResponse<GetPrisspottersResponse>
    >(url);

    if (response.isError || !response.data) {
      return { isError: true, IsNotAuthorized: response.IsNotAuthorized };
    }

    const data = response.data;
    if (data.isOk) {
      return { isError: false, data: data.result };
    } else {
      return {
        isError: true,
        error:
          "Kan ikke indlæse Ferieafløser. " + (data.errorDescription ?? ""),
      };
    }
  }
  
  createPrisspotter(
    actor: Prisspotter,
    password: string
  ): Promise<ApiResponse<number>> {
    return this.createSubordinateUser(actor.name, actor.userName, "", "", password, UserRole.Prisspotter);
  }

  editPrisspotter(
    actorNumber: number,
    password: string
  ): Promise<ApiResponse<void>> {
    return this.editSubordinateUser(actorNumber,password,"","");
  }
  
  async removeSubordinateUser(actorNumber: number): Promise<ApiResponse<void>> {
    const url = `${process.env.REACT_APP_SERVICE}api/user/RemoveSubordinateUser`;

    const response = await this.httpService.post<FuelServerResponse<void>>(
      url,
      { actorNumber }
    );

    if (response.isError || !response.data) {
      return { isError: true, IsNotAuthorized: response.IsNotAuthorized };
    }

    const data = response.data;

    if (data.isOk) {
      return { isError: false };
    } else {
      return {
        isError: true,
        error: "Kan ikke fjerne bruger. " + (data.errorDescription ?? ""),
      };
    }
  }

  async addPermissions(
    actorNumber: number,
    startDate: Date | string,
    endDate: Date | string,
    stationNumbers: number[]
  ): Promise<ApiResponse<void>> {
    const url = `${process.env.REACT_APP_SERVICE}api/user/AddStationPermissions`;

    const response = await this.httpService.post<FuelServerResponse<void>>(
      url,
      { actorNumber, startDate, endDate, stationNumbers }
    );

    if (response.isError || !response.data) {
      return { isError: true, IsNotAuthorized: response.IsNotAuthorized };
    }

    const data = response.data;

    if (data.isOk) {
      return { isError: false };
    } else {
      return {
        isError: true,
        error: "Kan ikke tilføje aftale. " + (data.errorDescription ?? ""),
      };
    }
  }

  async removePermissions(
    actorNumber: number,
    agreementNumbers: number[]
  ): Promise<ApiResponse<void>> {
    const url = `${process.env.REACT_APP_SERVICE}api/user/RemoveStationPermissions`;

    const response = await this.httpService.post<FuelServerResponse<void>>(
      url,
      { actorNumber, agreementNumbers }
    );

    if (response.isError || !response.data) {
      return { isError: true, IsNotAuthorized: response.IsNotAuthorized };
    }

    const data = response.data;

    if (data.isOk) {
      return { isError: false };
    } else {
      return {
        isError: true,
        error: "Kan ikke fjerne aftale. " + (data.errorDescription ?? ""),
      };
    }
  }

  private async createSubordinateUser(
    name: string,
    userName: string,
    email: string,
    phone: string,
    password: string,
    role: UserRole,
  ): Promise<ApiResponse<number>> {
    const url = `${process.env.REACT_APP_SERVICE}api/user/CreateSubordinateUser`;

    const response = await this.httpService.post<FuelServerResponse<number>>(
      url,
      { name, userName, email, phone, password, role }
    );

    if (response.isError || !response.data) {
      return { isError: true, IsNotAuthorized: response.IsNotAuthorized };
    }

    const data = response.data;

    if (data.isOk) {
      return { isError: false, data: data.result };
    } else {
      return {
        isError: true,
        error: "Kan ikke oprette bruger. " + (data.errorDescription ?? ""),
      };
    }
  }

  private async editSubordinateUser(
    actorNumber: number,
    password: string,
    email: string,
    phone: string
  ): Promise<ApiResponse<void>> {
    const url = `${process.env.REACT_APP_SERVICE}api/user/EditSubordinateUser`;

    const response = await this.httpService.post<FuelServerResponse<void>>(
      url,
      { actorNumber, email, phone, password }
    );

    if (response.isError || !response.data) {
      return { isError: true, IsNotAuthorized: response.IsNotAuthorized };
    }

    const data = response.data;

    if (data.isOk) {
      return { isError: false };
    } else {
      return {
        isError: true,
        error: "Kan ikke ændre bruger. " + (data.errorDescription ?? ""),
      };
    }
  }
}

import React from "react";
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  Slider,
  Typography,
} from "@material-ui/core";

import { Controller, useForm } from "react-hook-form";
import { UnoxColors } from "../../../constants/theme";
import { LocalSettings } from "../../../Models/LocalSettings";

type LocalSettingsProps = {
  settings: LocalSettings;
  updateSettings: (updatedSettings: LocalSettings) => void;
};

type LocalSettingsFormData = {
  SortUnoxStationsByLocation: boolean;
  SortCompetitorStationsByLocation: boolean;
  LocationGettingTimeoutSeconds: number;
};

const LocalSettingsComponent: React.FC<LocalSettingsProps> = (props) => {
  const defaultValues = {
    ...props.settings,
    LocationGettingTimeoutSeconds: props.settings.LocationGettingTimeout / 1000,
  };
  const formMethods = useForm<LocalSettingsFormData>({ defaultValues });

  const { handleSubmit, control } = formMethods;

  const submit = async (data: LocalSettingsFormData) => {
    const updatedSettings: LocalSettings = {
      SortUnoxStationsByLocation: data.SortUnoxStationsByLocation,
      SortCompetitorStationsByLocation: data.SortCompetitorStationsByLocation,
      LocationGettingTimeout: data.LocationGettingTimeoutSeconds * 1000, // convert seconds to ms
    };
    props.updateSettings(updatedSettings);
  };

  return (
    <>
      <form
        onSubmit={handleSubmit(submit)}
        style={{ width: "100%", maxWidth: 400 }}
      >
        <FormControl>
          <FormControlLabel
            control={
              <Controller
                render={({ onChange, value, ref }) => (
                  <Checkbox
                    onChange={(e) => onChange(e.target.checked)}
                    checked={value}
                    inputRef={ref}
                    style={{ height: 10 }}
                    color="primary"
                  />
                )}
                name="SortCompetitorStationsByLocation"
                control={control}
              />
            }
            label={
              <Typography variant="body2">
                Sorter prisrapportering stationer efter placering
              </Typography>
            }
          />
        </FormControl>

        <FormControl>
          <FormControlLabel
            control={
              <Controller
                render={({ onChange, value, ref }) => (
                  <Checkbox
                    onChange={(e) => onChange(e.target.checked)}
                    checked={value}
                    inputRef={ref}
                    style={{ height: 10 }}
                    color="primary"
                  />
                )}
                name="SortUnoxStationsByLocation"
                control={control}
              />
            }
            label={
              <Typography variant="body2">
                Sorter mine stationer efter placering
              </Typography>
            }
          />
        </FormControl>

        <Typography variant="body2">GPS timeout:</Typography>
        <Controller
          name="LocationGettingTimeoutSeconds"
          control={control}
          render={(props) => (
            <Slider
              {...props}
              onChange={(_, value) => {
                props.onChange(value);
              }}
              valueLabelDisplay="off"
              min={1}
              max={60}
              step={1}
              marks={[
                { value: 1, label: "1s" },
                { value: 15, label: "15s" },
                { value: 30, label: "30s" },
                { value: 45, label: "45s" },
                { value: 60, label: "60s" },
              ]}
            />
          )}
        />
        <Grid item container justify="center">
          <Button
            style={{
              backgroundColor: UnoxColors.Green,
              color: UnoxColors.White,
            }}
            variant="contained"
            type="submit"
          >
            Gem ændringer
          </Button>
        </Grid>
      </form>
    </>
  );
};

export default LocalSettingsComponent;

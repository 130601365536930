import React from "react";
import {
  Card,
  Grid,
  Typography,
  CardContent,
  CardHeader,
} from "@material-ui/core";
import { Phone, Email } from "@material-ui/icons";

import { Contact } from "../../../Models/Contact";
import { UnoxColors } from "../../../constants/theme";

type ContactRecordProps = {
  contact: Contact;
};

const ContactRecord: React.FC<ContactRecordProps> = ({ contact }) => {
  return (
    <Card>
      <CardContent style={{ padding: 8 }}>
        <Grid container direction="column">
          <Grid item>
            <Typography variant="subtitle2" style={{ fontWeight: "bold" }}>
              {contact.name}
            </Typography>
          </Grid>

          {contact.description_line1 && (
            <Grid item>
              <Typography variant="body2">
                {contact.description_line1}
              </Typography>
            </Grid>
          )}

          {contact.description_line2 && (
            <Grid item>
              <Typography variant="body2">
                {contact.description_line2}
              </Typography>
            </Grid>
          )}

          {contact.description_line3 && (
            <Grid item>
              <Typography variant="body2">
                {contact.description_line3}
              </Typography>
            </Grid>
          )}

          {contact.description_line4 && (
            <Grid item>
              <Typography variant="body2">
                {contact.description_line4}
              </Typography>
            </Grid>
          )}

          {contact.description_line5 && (
            <Grid item>
              <Typography variant="body2">
                {contact.description_line5}
              </Typography>
            </Grid>
          )}

          {contact.description_line6 && (
            <Grid item>
              <Typography variant="body2">
                {contact.description_line6}
              </Typography>
            </Grid>
          )}

          {contact.description_line7 && (
            <Grid item>
              <Typography variant="body2">
                {contact.description_line7}
              </Typography>
            </Grid>
          )}

          {contact.phone && (
            <Grid item container spacing={1}>
              <Grid item>
                <Phone fontSize="small" />
              </Grid>
              <Grid item>
                <a
                  href={"tel:" + contact.phone}
                  style={{ color: UnoxColors.Black }}
                >
                  <Typography variant="body2">{contact.phone}</Typography>
                </a>
              </Grid>
            </Grid>
          )}

          {contact.email && (
            <Grid item container spacing={1}>
              <Grid item>
                <Email fontSize="small" />
              </Grid>
              <Grid item>
                <a
                  href={"mailto:" + contact.email}
                  style={{ color: UnoxColors.Black }}
                >
                  <Typography variant="body2">{contact.email}</Typography>
                </a>
              </Grid>
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ContactRecord;
